<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.userName" placeholder="请输入用户昵称" @change="userNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.storeName" placeholder="请输入店铺名称" @change="storeNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.type" placeholder="用户类型" @change="selectTypeChange" @clear="selectTypeClear" clearable>
                    <el-option v-for="item in select_Type_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="userName" label="昵称" width="" sortable></el-table-column>
            <!--<el-table-column prop="bindStoreName" label="绑定店铺" width="" sortable></el-table-column>-->
            <el-table-column prop="storeName" label="我的店铺" width="" sortable></el-table-column>
            <el-table-column prop="type" label="类型" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.type == 1 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.type == 1 ? "店长" : scope.row.type == 2 ? "店员" : "异常" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="bankName" label="银行名称" width="" sortable></el-table-column>
            <el-table-column prop="accountName" label="账户名" width="" sortable></el-table-column>
            <el-table-column prop="cardNumber" label="账号" width="" sortable></el-table-column>
            <!--<el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>-->
        </el-table>


        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="银行名称" prop="bankName">
                    <el-input v-model="editForm.bankName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="账户地址" prop="bankAddress">
                    <el-input v-model="editForm.bankAddress" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户行" prop="accountBank">
                    <el-input v-model="editForm.accountBank" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="账户名" prop="accountName">
                    <el-input v-model="editForm.accountName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="卡号" prop="cardNumber">
                    <el-input v-model="editForm.cardNumber" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="editForm.idcard" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>



    </section>
</template>

<script>
    import {
        getRoleListPage,
        getStoreAdminPageList,
        getUserBankInfo,
        editUserBankInfo,
        exportStoreAdminList
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_Type_options: [{
                    value: '1',
                    label: '店长'
                }, {
                    value: '2',
                    label: '店员'
                }],
                filters: {
                    userName: "",
                    storeName: "", //双向绑定搜索内容
                    type: '',

                },
                datalist: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                //编辑界面配置
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    //name: [{ required: true, message: "请输入分类名称", trigger: "blur" },],
                    //nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
                    //passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
                },
                editForm: {},//编辑界面数据
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            userNameChange(val) {
                this.filters.userName = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            selectTypeChange(val) {
                this.filters.type = val;
            },
            selectTypeClear() {
                this.filters.type = "";
            },
            //获取店铺申请列表
            getDataList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    userName: this.filters.userName,
                    storeName: this.filters.storeName,
                    type: this.filters.type,
                };
                this.listLoading = true;
                //NProgress.start();
                getStoreAdminPageList(para).then((res) => {
                    this.datalist = res.data;
                    this.total = res.count;
                    this.listLoading = false;
                });
            },

            //************按钮事件************

            //导出表格
            exportExcel() {
                let row = this.currentRow;
                this.$confirm("确认导出店长数据吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    //let para = { id: row.id };
                    exportStoreAdminList({}).then((res) => {
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            console.log(res.data);
                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });
            },

            //显示编辑界面
            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的用户！",
                        type: "error",
                    });
                    return;
                }
                getUserBankInfo({ id: row.userId }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        this.editForm = Object.assign({ }, res.data);
                    }
                    else {
                        this.editForm = { };
                    }
                    this.editFormVisible = true;
                    this.editLoading = false;
                });
            },


            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let row = this.currentRow;
                            if (!row) {
                                this.$message({
                                    message: "请选择要编辑的用户！",
                                    type: "error",
                                });
                                return;
                            }
                            let para = Object.assign({ userId: row.id }, this.editForm);
                            editUserBankInfo(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getDataList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.editLoading = false;
                            });
                        });
                    }
                });
            },

            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getDataList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
