<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="name" label="姓名" width=""></el-table-column>
            <el-table-column prop="mobile" label="电话" width=""></el-table-column>
            <el-table-column prop="rebate" label="返利" width=""></el-table-column>
            <!--类型[0保险]-->
            <el-table-column prop="type" label="类型" width="">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.type == 0 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.type == 0 ? "保险" : "其他" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--状态[0未处理1已处理2已返利]-->
            <el-table-column prop="state" label="状态" width="">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'success' :scope.row.state == 1 ? 'danger' : 'danger'"
                            disable-transitions>
                        {{ scope.row.state == 0 ? "未处理" : scope.row.state == 1 ? '已处理' : "已返利" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="身份证照片">
                <template slot-scope="scope">
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; max-height: 100px;margin-right:10px;"
                                  :src="item"
                                  :preview-src-list="scope.row.idCardImages"
                                  v-for="item in scope.row.idCardImages">
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="驾驶本照片">
                <template slot-scope="scope">
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; max-height: 100px; margin-right: 10px;"
                                  :src="item"
                                  :preview-src-list="scope.row.drivingLicenseImages"
                                  v-for="item in scope.row.drivingLicenseImages">
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="提交时间" width="160"></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--返利界面-->
        <el-dialog title="返利"
                   :visible.sync="rebateFormVisible"
                   v-model="rebateFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="rebateForm"
                     label-width="80px"
                     :rules="rebateFormRules"
                     ref="rebateForm">
                <el-form-item label="姓名" prop="name">
                    {{ rebateForm.name  }}
                </el-form-item>
                <el-form-item label="返利金额" prop="rebate">
                    <el-input v-model="rebateForm.rebate" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注信息" prop="remarks">
                    <el-input v-model="rebateForm.remarks" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="rebateFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="rebateSubmit"
                           :loading="rebateLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getEnrollListPage,
        dealEnrol,
        rebateEnrol,
        removeEnrol,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "",
                },
                datalist: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列

                ///返利弹窗
                rebateFormVisible: false, //编辑界面是否显示
                rebateLoading: false,
                rebateFormRules: {
                    //balance: [{ required: true, message: "请输入充值金额", trigger: "blur" },],
                },
                //返利界面数据
                rebateForm: {},

            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDatas();
            },
            //获取列表
            getDatas() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                };
                this.listLoading = true;
                getEnrollListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该数据吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        removeEnrol(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getDatas();
                        });
                    })
                    .catch(() => { });
            },

            //显示返利界面
            handleRebate() {
                console.log("1111111111111111");
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要返利的数据！",
                        type: "error",
                    });
                    return;
                }
                this.rebateFormVisible = true;
                this.rebateForm = Object.assign({}, row);
            },

            //处理
            handleDeal() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要处理的数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认处理该数据吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id, data: 0 };
                        dealEnrol(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getDatas();
                        });
                    })
                    .catch(() => { });
            },

            //************提交方法************
            //返利
            rebateSubmit: function () {
                this.$refs.rebateForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认给 【" + this.rebateForm.name + "】 返利 【" + this.rebateForm.rebate + "】 吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            console.log(this.rebateForm);
                            let para = Object.assign({}, this.rebateForm);
                            rebateEnrol(para).then((res) => {
                                if (res.code == 0) {
                                    this.rebateLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["rebateForm"].resetFields();
                                    this.rebateForm = false;
                                    this.getDatas();
                                    this.rebateFormVisible = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },

            //handleChange(value) {
            //    console.log(value);
            //},


            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getDatas();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
    .demo-image__preview{
        display:flex;
    }
</style>
