<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.goodsName" placeholder="请输入商品名称" @change="goodsNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入超市名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.type" placeholder="订单类型" @change="selectTypeChange" @clear="selectTypeClear" clearable>
                    <el-option v-for="item in select_type_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="block">
                    <el-date-picker v-model="filters.picker_end"
                                    type="daterange"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="datePickeChange"
                                    :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
            </el-form-item>
            <br />
            <el-form-item>
                <el-select v-model="filters.orderField" placeholder="排序" @change="selectOrderFieldChange" @clear="selectPayStateClear" clearable style="width:120px">
                    <el-option v-for="item in select_payState_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.orderDirection" placeholder="排序方式" @change="selectOrderDirectionChange" @clear="selectOrderDirectionClear" clearable style="width:120px">
                    <el-option v-for="item in select_sort_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="orderNumber" label="订单编号" width=""></el-table-column>
            <el-table-column prop="storeName" label="超市名称" width=""></el-table-column>
            <el-table-column prop="userNickName" label="用户昵称" width=""></el-table-column>
            <el-table-column label="商品信息" width="300">
                <template slot-scope="scope">
                    <ul style="padding-inline-start: 0;" v-for="(item,i) in scope.row.items">
                        <li style="display:flex;">
                            <img :src="item.image" style="width:50px;height:50px;" />
                            <p>{{ item.name }}</p>
                            <p>￥{{ item.price }}</p>
                            <p>*{{ item.num }}</p>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column label="订单金额(￥)" width="">
                <template slot-scope="scope">
                    <el-tag type="success" disable-transitions>
                        ￥{{ scope.row.orderAmount }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="实付款(￥)" width="">
                <template slot-scope="scope">
                    <el-tag type="danger" disable-transitions>
                        ￥{{ scope.row.payedAmount }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="memo" label="买家备注" width=""></el-table-column>
            <el-table-column prop="orderType" label="订单类型" width="">
                <template slot-scope="scope">
                    <el-tag type="success" disable-transitions v-if="scope.row.orderType == 1">
                        普通订单
                    </el-tag>
                    <el-tag type="warning" disable-transitions v-else-if="scope.row.orderType == 2">
                        服务订单
                    </el-tag>
                    <el-tag type="danger" disable-transitions v-else-if="scope.row.orderType == 3">
                        接龙订单
                    </el-tag>
                    <el-tag type="success" disable-transitions v-else-if="scope.row.orderType == 6">
                        采购订单
                    </el-tag>
                    <el-tag type="success" disable-transitions v-else="scope.row.orderType == 6">
                        其他订单
                    </el-tag>
                    <!--<el-tag :type="scope.row.orderType == 1 ? 'warning' :scope.row.orderType == 2 ? 'success': 'danger'" disable-transitions>
                        {{ scope.row.orderType == 1 ? "普通订单" :scope.row.orderType == 2 ? "服务订单" : scope.row.orderType == 3 ? "接龙订单" : "其他订单" }}
                    </el-tag>-->
                </template>
            </el-table-column>
            <el-table-column prop="paymentTime" label="支付时间" width="160"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
            <!--<el-table-column fixed="right"
                             label="操作"
                             width="100">
                <template slot-scope="scope">
                </template>
            </el-table-column>-->
        </el-table>


        <!--生成配送单界面-->
        <el-dialog title="生成配送单"
                   :visible.sync="createDeliverFormVisible"
                   v-model="createDeliverFormVisible"
                   @close="formclear"
                   :close-on-click-modal="false">
            <el-form :model="createDeliverForm"
                     label-width="80px"
                     :rules="createDeliverFormRules"
                     ref="createDeliverForm">
                <el-form-item label="配送时间" prop="deliverTime">
                    <div class="block">
                        <el-date-picker v-model="createDeliverForm.picker_end"
                                        type="daterange"
                                        align="right"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="createDeliverDatePickeChange"
                                        :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="配送员">
                    <el-cascader placeholder="请选择配送员，支持搜索"
                                 style="width: 220px"
                                 v-model="createDeliverForm.deliverClerk"
                                 :options="deliverClerkList"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 @change="deliverClerkChange"
                                 v-if="!getdeliverClerkLoading"
                                 clearable></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="getdeliverClerkLoading"></el-cascader>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input v-model="createDeliverForm.remarks" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="createDeliverFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="createDeliverSubmit"
                           :loading="createDeliverLoading">提交</el-button>
            </div>
        </el-dialog>
        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
    import {
        getRoleListPage,
        getToDivideOrderListPage,
        getDeliverClerkSelectList,
        createDeliverOrder,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_payState_options: [{
                    value: 'orderNum',
                    label: '订单编号'
                }, {
                    value: 'paymentTime',
                    label: '支付时间'
                }, {
                    value: 'orderAmount',
                    label: '订单金额'
                }, {
                    value: 'orderType',
                    label: '订单类型'
                }, {
                    value: 'createTime',
                    label: '创建时间'
                }],
                select_sort_options: [{
                    value: 'asc',
                    label: '顺序排列'
                }, {
                    value: 'desc',
                    label: '倒序排列'
                }],
                select_type_options: [{
                    value: '1',
                    label: '普通订单'
                }, {
                    value: '3',
                    label: '接龙订单'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                filters: {
                    goodsName: '',
                    storeName: "", //双向绑定搜索内容
                    type: '',
                    payState: '',
                    picker_start: '',
                    picker_end: '',
                    deliverClerk: 0,
                },
                datalist: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                selsids: [], //列表选中列订单号

                //生成配送单界面配置信息
                createDeliverFormVisible: false, //界面是否显示
                createDeliverLoading: false,
                createDeliverFormRules: {
                    //type: [{ required: true, message: "请选择店铺类型", trigger: "blur" }],
                },
                createDeliverForm: {},//生成配送单界面数据
                getdeliverClerkLoading: false,
                isResouceShow: 0,

                deliverClerkList: [],
            };
        },
        methods: {
            formclear() {
                //this.address = "";
                //this.addressInfo = "";
                //this.logoImage = "";
                //this.businessLicenceImage = "";
                //this.fileList = [];
                //this.dialogImageUrl = '';
                //this.position = {};
            },
            handleClick(row) {
                console.log(row);
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this[item.func].apply(this, item);
            },
            deliverClerkChange(val) {
                console.log(val);
                if (val.length > 0) {
                    this.createDeliverForm.deliverClerk = val[val.length - 1];
                }
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
            goodsNameChange(val) {
                this.filters.goodsName = val;
            },
            nameChange(val) {
                this.filters.storeName = val;
            },
            selectTypeChange(val) {
                this.filters.type = val;
            },
            selectTypeClear() {
                this.filters.type = "";
            },
            selectOrderFieldChange(val) {
                this.filters.orderField = val;
            },
            selectOrderFieldClear() {
                this.filters.orderField = "";
            },
            selectOrderDirectionChange(val) {
                this.filters.orderDirection = val;
            },
            selectOrderDirectionClear() {
                this.filters.orderDirection = "";
            },
            createDeliverDatePickeChange(val) {
                if (val == null) {
                    this.createDeliverForm.startTime = "";
                    this.createDeliverForm.endTime = "";
                }
                else if (val.length == 2) {
                    this.createDeliverForm.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate() + " 00:00:00";
                    this.createDeliverForm.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                }
            },
            datePickeChange(val) {
                if (val == null) {
                    this.filters.startTime = "";
                    this.filters.endTime = "";
                }
                else if (val.length == 2) {
                    this.filters.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate();
                    this.filters.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                }
            },
            //获取店铺列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    goodsName: this.filters.goodsName,
                    storeName: this.filters.storeName,
                    type: this.filters.type,
                    orderField: this.filters.orderField,
                    orderDirection: this.filters.orderDirection,
                    startTime: this.filters.startTime,
                    endTime: this.filters.endTime,
                };
                this.listLoading = true;
                getToDivideOrderListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //显示生成配送单页面
            handleCreateDeliver() {
                let that = this;
                var ordernumlist = that.selsids;
                console.log(ordernumlist);
                /* let row = this.currentRow;*/
                if (ordernumlist.length == 0) {
                    this.$message({
                        message: "请选择要生成配送单的订单！",
                        type: "error",
                    });
                    return;
                }
                that.createDeliverForm = [];
                that.createDeliverFormVisible = true;
                that.getdeliverClerkLoading = true;
                //获取配送员列表
                getDeliverClerkSelectList({}).then((res) => {
                    if (res.data.code == 0) {
                        that.getdeliverClerkLoading = false;
                        that.deliverClerkList = res.data.data;
                        //that.deliverClerkList.push();
                        console.log(that.deliverClerkList);
                    }
                });
            },

            //************提交方法************
            //生成配送单
            createDeliverSubmit: function () {
                let _this = this;
                _this.createDeliverLoading = true;
                let para = {
                    ids: _this.selsids,
                    deliverClerkId: _this.createDeliverForm.deliverClerk,
                    remarks: _this.createDeliverForm.remarks,
                    startTime: _this.createDeliverForm.startTime,
                    endTime: _this.createDeliverForm.endTime,
                };
                createDeliverOrder(para).then((res) => {
                    _this.createDeliverLoading = false;
                    if (res.code == 0) {
                        _this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        _this.createDeliverFormVisible = false;
                        this.getList();
                    } else {
                        _this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
            },
            selsChange: function (sels) {
                this.sels = sels;
                this.selsids = sels.map(item => item.orderNumber);
                console.log(this.selsids)
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
