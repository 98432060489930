<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.nickName" placeholder="请输入昵称" @change="nickNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.realName" placeholder="请输入真实姓名" @change="realNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectStateChange" @clear="selectStateClear" clearable>
                    <el-option v-for="item in select_state_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="avatar" label="头像" width="" sortable>
                <el-avatar :size="medium" :src="avatar"></el-avatar>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称" width=""></el-table-column>
            <el-table-column prop="realname" label="真实姓名" width=""></el-table-column>
            <el-table-column prop="emergencyContact" label="紧急联系人"></el-table-column>
            <el-table-column prop="emergencyMobile" label="紧急联系电话"></el-table-column>
            <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
            <el-tag :type="scope.row.status == 2 ? 'success' : 'danger'"
                    disable-transitions>
                {{ scope.row.status == 1 ? "正在审核" : scope.row.status == 2 ? "审核成功" : "审核失败" }}
            </el-tag>
        </template>
    </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>



    </section>
</template>

<script>
    import {
        getRoleListPage,
        getDeliverClerkApplyListPage,
        auditSuccess,
        auditFail,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_state_options: [{
                    value: '1',
                    label: '审核成功'
                }, {
                    value: '2',
                    label: '审核失败'
                }],
                filters: {
                    nickName: "",
                    realName: "", //双向绑定搜索内容
                    state: '',

                },
                datalist: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            nickNameChange(val) {
                this.filters.nickName = val;
            },
            realNameChange(val) {
                this.filters.realName = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            selectStateChange(val) {
                this.filters.state = val;
            },
            selectStateClear() {
                this.filters.state = "";
            },
            //获取列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    userName: this.filters.userName,
                    storeName: this.filters.storeName,
                    type: this.filters.type,
                };
                this.listLoading = true;
                getDeliverClerkApplyListPage(para).then((res) => {
                    console.log(res);
                    this.datalist = res.data.data;
                    this.total = res.data.count;
                    this.listLoading = false;
                });
            },


            //************按钮事件************
            //审核成功
            auditSuccess() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要审核的数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认审核成功?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        auditSuccess(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "审核成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getList();
                        });
                    })
                    .catch(() => { });
            },

            //上架
            auditFail() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要审核的数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认审核失败?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        auditFail(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getList();
                        });
                    })
                    .catch(() => { });
            },


            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
    .demo-table-expand {
        font-size: 0;
    }

        .demo-table-expand label {
            width: 90px;
            color: #99a9bf;
        }

        .demo-table-expand .el-form-item {
            margin-right: 0;
            margin-bottom: 0;
            width: 50%;
        }
</style>
