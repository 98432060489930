<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="userName" label="用户" width="" sortable></el-table-column>
            <el-table-column prop="storeName" label="店铺" width="" sortable></el-table-column>
            <!--状态[0隐藏1显示]-->
            <el-table-column prop="type" label="类型" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.type == 'user' ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.type == 'user' ? "用户" : "店铺" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额" width="" sortable></el-table-column>
            <el-table-column prop="bankName" label="银行名称" width="" sortable></el-table-column>
            <el-table-column prop="accountName" label="账户名" width="" sortable></el-table-column>
            <el-table-column prop="cardNumber" label="卡号" width="170" sortable></el-table-column>
            <!--状态[0隐藏1显示]-->
            <el-table-column prop="state" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'success' :scope.row.state == 1 ? 'success': 'danger'"
                            disable-transitions>
                        {{ scope.row.state == 0 ? "未处理" : scope.row.state == 1 ? "成功" :"失败" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--<el-table-column prop="remarks" label="备注" width="" sortable></el-table-column>-->
            <el-table-column prop="log" label="日志" width="" sortable></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
    import {
        getRoleListPage,
        getWithdrawalListPage,
        updateWithdrawalSuccess,
        updateWithdrawalFail,
        removeWithdrawal,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "",
                },
                datalist: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            //获取列表
            getDataList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                };
                this.listLoading = true;
                getWithdrawalListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的申请！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该申请吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        removeWithdrawal(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },
            //提现成功
            handleSuccess() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要操作的申请！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认提现成功吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id, data: 1 };
                        updateWithdrawalSuccess(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: res.msg,
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },
            //提现失败
            handleFail() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要操作的申请！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认提现失败吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id, data: 0 };
                        updateWithdrawalFail(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },

            handleChange(value) {
                console.log(value);
            },

            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getDataList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
