<template>
    <section>
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <el-table :data="datalist"
                  highlight-current-row
                  default-expand-all
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  @current-change="selectCurrentRow"
                  row-key="id"
                  border
                  :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                  style="width: 100%"
                  ref="table">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="sort" label="排序" width="" sortable></el-table-column>
            <el-table-column prop="name" label="名称" width="" sortable></el-table-column>
            <!--使用状态-->
            <el-table-column prop="state" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.isShow ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.isShow ? "正常" : "禁用" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="50"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>


        <!--新增界面-->
        <el-dialog title="新增"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="80px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="addForm.sort" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="addForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="pidArr" label="分类" width sortable>
                    <el-cascader placeholder="请选择，支持搜索功能"
                                 style="width: 400px"
                                 v-model="addForm.pidArr"
                                 :options="categorys"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 v-if="!addLoading"></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="addLoading"></el-cascader>
                </el-form-item>
                <el-form-item label="状态" prop="isShow">
                    <el-radio-group v-model="addForm.isShow">
                        <el-radio :label="true">正常</el-radio>
                        <el-radio :label="false">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>


        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">

                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editForm.sort" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="editForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="pidArr" label="分类" width sortable>
                    <el-cascader placeholder="请选择，支持搜索功能"
                                 style="width: 400px"
                                 v-model="editForm.pidArr"
                                 :options="categorys"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 v-if="!editLoading"></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="editLoading"></el-cascader>
                </el-form-item>
                <el-form-item label="状态" prop="isShow">
                    <el-radio-group v-model="editForm.isShow">
                        <el-radio :label="true">正常</el-radio>
                        <el-radio :label="false">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getServicesCategoryPageTree,
        getServicesCategoryTree,
        addServicesCategory,
        getServicesCategory,
        editServicesCategory,
        removeServicesCategory
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '正常'
                }, {
                    value: '2',
                    label: '禁用'
                }],
                filters: {
                    name: "", //双向绑定搜索内容
                    state: '',
                },
                datalist: [],
                categorys: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 10,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                //新增界面配置
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    name: [{ required: true, message: "请输入分类名称", trigger: "blur" },],
                },
                addForm: {},//新增界面数据

                //编辑界面配置
                editFormVisible: false, //新增界面是否显示
                editLoading: false,
                editFormRules: {
                    name: [{ required: true, message: "请输入分类名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据
                isResouceShow: 0,
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            nameChange(val) {
                this.filters.name = val;
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            //获取列表
            getDataList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                    state: this.filters.state,
                };
                this.listLoading = true;
                //NProgress.start();
                getServicesCategoryPageTree(para).then((res) => {
                    console.log(res);
                    //this.total = res.data.count;
                    this.datalist = res.data;
                    this.listLoading = false;
                    //NProgress.done();
                });
            },

            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的分类！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该分类吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        removeServicesCategory(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },

            //显示新增界面
            handleAdd() {

                this.addFormVisible = true;
                this.addLoading = false;
            },

            //显示编辑界面
            handleEdit() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的分类！",
                        type: "error",
                    });
                    return;
                }
                getServicesCategory({ id: row.id }).then((res) => {
                    console.log(res);
                    if (res.data.code == 0) {
                        that.categorys = [];
                        that.categorys.push(res.data.data.categoryTree);
                        that.editForm = Object.assign({}, res.data.data);
                        that.editFormVisible = true;
                    }
                    this.editLoading = false;
                });
            },


            //************提交方法************
            //新增
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            let para = Object.assign({}, this.addForm);
                            if (para.pidArr != undefined) {
                                para.parentId = para.pidArr.pop();
                            }
                            addServicesCategory(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.addFormVisible = false;
                                    this.getDataList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.editForm);
                            if (para.pidArr != undefined) {
                                para.parentId = para.pidArr.pop();
                            }
                            editServicesCategory(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getDataList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },

            selsChange: function (sels) {
                this.sels = sels;
            },
            getServicesCategoryList() {
                let that = this;
                getServicesCategoryTree({}).then((res) => {
                    if (res.data.code == 0) {
                        that.categorys = [];
                        that.categorys.push(res.data.data);
                    }
                });
            }
        },
        mounted() {
            this.getDataList();
            this.getServicesCategoryList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
