<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <el-tag type="success">商品/服务统计报表</el-tag>
        <!--数据表一-->
        <el-table :data="datalist"
                  :border="border"
                  :show-summary="showSummary"
                  highlight-current-row
                  :span-method="arraySpanMethod"
                  v-loading="listLoading"
                  style="width: 100%;margin-bottom:50px">
            <el-table-column prop="goodsName" label="品名/服务名称" width="250"></el-table-column>
            <el-table-column prop="batch" label="商品批次"></el-table-column>
            <el-table-column prop="totalNum" label="销售数量"></el-table-column>
            <el-table-column prop="payMethod" label="支付方式"></el-table-column>
            <el-table-column prop="totalMoney" label="收入总金额"></el-table-column>
            <el-table-column prop="tlSXF" label="通联手续费"></el-table-column>
            <el-table-column prop="realIncome" label="实际到账"></el-table-column>
            <el-table-column prop="realCost" label="实际成本"></el-table-column>
            <el-table-column prop="storeCost" label="供货价"></el-table-column>
            <el-table-column prop="netProfits" label="公司净利润"></el-table-column>
            <el-table-column prop="YSFee" label="预设费用"></el-table-column>
            <el-table-column prop="FZFee" label="预计分账金额"></el-table-column>
            <el-table-column prop="cashFee" label="提现费用"></el-table-column>
        </el-table>
        <el-tag type="success">店铺统计报表</el-tag>
        <!--数据表二-->
        <el-table :data="datalist2"
                  :border="border"
                  highlight-current-row
                  :span-method="arraySpanMethod2"
                  v-loading="listLoading"
                  style="width: 100%;">
            <el-table-column prop="storeName" label="店铺名称" width="180"></el-table-column>
            <el-table-column prop="goodsName" label="品名/服务名称" width="220"></el-table-column>
            <el-table-column prop="batch" label="商品批次"></el-table-column>
            <el-table-column prop="totalNum" label="销售数量"></el-table-column>
            <el-table-column prop="payMethod" label="支付方式"></el-table-column>
            <el-table-column prop="totalMoney" label="收入总金额"></el-table-column>
            <el-table-column prop="tlSXF" label="通联手续费"></el-table-column>
            <el-table-column prop="realIncome" label="实际到账"></el-table-column>
            <el-table-column prop="realCost" label="实际成本"></el-table-column>
            <el-table-column prop="storeCost" label="供货价"></el-table-column>
            <el-table-column prop="netProfits" label="公司净利润"></el-table-column>
            <el-table-column prop="YSFee" label="预设费用"></el-table-column>
            <el-table-column prop="FZFee" label="预计分账金额"></el-table-column>
            <el-table-column prop="cashFee" label="提现费用"></el-table-column>
        </el-table>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        exportStatistics,
        getStatisticsData,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {

                },
                datalist: [],
                datalist2: [],
                roles: [],
                buttonList: [],
                border: true,
                showSummary: true,
                listLoading: false,

                goodsArr: [], //合并名称
                goods_index: 0, //合并名称索引
                batchArr: [], //合并批次
                batch_index: 0, //合并批次索引
                paymentMethodArr: [], //合并支付方式
                paymentMethod_index: 0, //合并支付方式索引


                storeArr :[],//合并店铺
                store_index : 0,//合并店铺索引
                goodsArr2 :[], //合并名称
                batchArr2 :[], //合并批次
                paymentMethodArr2 :[], //合并支付方式

            };
        },
        methods: {
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            // 初始化合并行数组
            mergeInit() {
                this.goodsArr = []; //合并名称
                this.goods_index = 0; //合并名称索引
                this.batchArr = []; //合并批次
                this.batch_index = 0; //合并批次索引
                this.paymentMethodArr = []; //合并支付方式
                this.paymentMethod_index = 0; //合并支付方式索引
            },
            // 初始化合并行数组
            mergeInit2() {
                this.goods_index = 0; //合并名称索引
                this.batch_index = 0; //合并批次索引
                this.paymentMethod_index = 0; //合并支付方式索引

                this.storeArr = [];//合并店铺
                this.store_index = 0;//合并店铺索引
                this.goodsArr2 = []; //合并名称
                this.batchArr2 = []; //合并批次
                this.paymentMethodArr2 = []; //合并支付方式
            },
            //合并表格
            merge(data) {
                this.mergeInit();
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        if (i === 0) {
                            //第一行必须存在，以第一行为基准
                            this.goodsArr.push(1); //合并商品
                            this.goods_index = 0;

                            this.batchArr.push(1); //合并批次
                            this.batch_index = 0;

                            this.paymentMethodArr.push(1); //合并支付方式
                            this.paymentMethod_index = 0;
                           
                        } else {
                            // 判断当前元素与上一元素是否相同
                            // 合并商品
                            if (data[i].goodsid == data[i - 1].goodsid) {
                                this.goodsArr[this.goods_index] += 1;
                                this.goodsArr.push(0);
                            } else {
                                this.goodsArr.push(1);
                                this.goods_index = i;
                            }

                            // 合并批次
                            if (
                                data[i].batch == data[i - 1].batch &&
                                data[i].goodsid == data[i - 1].goodsid
                            ) {
                                this.batchArr[this.batch_index] += 1;
                                this.batchArr.push(0);
                            } else {
                                this.batchArr.push(1);
                                this.batch_index = i;
                            }

                            // 合并支付方式
                            if (
                                data[i].payMethod == data[i - 1].payMethod &&
                                data[i].batch == data[i - 1].batch &&
                                data[i].goodsid == data[i - 1].goodsid
                            ) {
                                this.paymentMethodArr[this.paymentMethod_index] += 1;
                                this.paymentMethodArr.push(0);
                            } else {
                                this.paymentMethodArr.push(1);
                                this.paymentMethod_index = i;
                            }
                        }
                    }
                }
            },
            //合并表格
            merge2(data) {
                this.mergeInit2();
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        if (i === 0) {
                            //第一行必须存在，以第一行为基准
                            this.storeArr.push(1); //合并店铺
                            this.store_index = 0;

                            this.goodsArr2.push(1); //合并商品
                            this.goods_index = 0;

                            this.batchArr2.push(1); //合并批次
                            this.batch_index = 0;

                            this.paymentMethodArr2.push(1); //合并支付方式
                            this.paymentMethod_index = 0;

                        } else {
                            // 判断当前元素与上一元素是否相同
                            // 合并店铺
                            if (data[i].storeid == data[i - 1].storeid) {
                                this.storeArr[this.store_index] += 1;
                                this.storeArr.push(0);
                            } else {
                                this.storeArr.push(1);
                                this.store_index = i;
                            }

                            // 合并商品
                            if (data[i].goodsid == data[i - 1].goodsid &&
                                data[i].storeid == data[i - 1].storeid
                            ) {
                                this.goodsArr2[this.goods_index] += 1;
                                this.goodsArr2.push(0);
                            } else {
                                this.goodsArr2.push(1);
                                this.goods_index = i;
                            }

                            // 合并批次
                            if (
                                data[i].batch == data[i - 1].batch &&
                                data[i].goodsid == data[i - 1].goodsid &&
                                data[i].storeid == data[i - 1].storeid
                            ) {
                                this.batchArr2[this.batch_index] += 1;
                                this.batchArr2.push(0);
                            } else {
                                this.batchArr2.push(1);
                                this.batch_index = i;
                            }

                            // 合并支付方式
                            if (
                                data[i].payMethod == data[i - 1].payMethod &&
                                data[i].batch == data[i - 1].batch &&
                                data[i].goodsid == data[i - 1].goodsid &&
                                data[i].storeid == data[i - 1].storeid
                            ) {
                                this.paymentMethodArr2[this.paymentMethod_index] += 1;
                                this.paymentMethodArr2.push(0);
                            } else {
                                this.paymentMethodArr2.push(1);
                                this.paymentMethod_index = i;
                            }
                        }
                    }
                }
            },

            //获取统计数据
            getList() {
                let para = {

                };
                this.listLoading = true;
                getStatisticsData(para).then((res) => {
                    this.datalist = res.data.data;
                    this.datalist2 = res.data.otherData;
                    this.merge(this.datalist);
                    this.merge2(this.datalist2);
                    console.log(this.storeArr);
                    console.log(this.goodsArr2);
                    console.log(this.batchArr2);
                    console.log(this.paymentMethodArr2);
                    this.listLoading = false;
                });
            },
            //rowIndex行    columnIndex列
            //  合并单元格
            arraySpanMethod({ row, column, rowIndex, columnIndex }) {
                const len = this.datalist.length;
                if (columnIndex === 0) {
                    //第一列 商品
                    const _row_1 = this.goodsArr[rowIndex];
                    const _col_1 = _row_1 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_1,
                        colspan: _col_1,
                    };
                }
                else if (columnIndex === 1) {
                    // 第二列 批次
                    const _row_2 = this.batchArr[rowIndex];
                    const _col_2 = _row_2 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_2,
                        colspan: _col_2,
                    };
                    // 第三列 支付方式
                } else if (columnIndex === 3) {
                    const _row_4 = this.paymentMethodArr[rowIndex];
                    const _col_4 = _row_4 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_4,
                        colspan: _col_4,
                    };
                }
                else if (rowIndex === len) {
                    return {
                        rowspan: 1,
                        colspan: 1
                    }
                }
            },

            //  合并单元格
            arraySpanMethod2({ row, column, rowIndex, columnIndex }) {
                const len = this.datalist2.length;
                if (columnIndex === 0) {
                    //第一列 店铺
                    const _row_1 = this.storeArr[rowIndex];
                    const _col_1 = _row_1 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_1,
                        colspan: _col_1,
                    };
                }
                else if (columnIndex === 1) {
                    //第一列 商品
                    const _row_2 = this.goodsArr2[rowIndex];
                    const _col_2 = _row_2 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_2,
                        colspan: _col_2,
                    };
                }
                else if (columnIndex === 2) {
                    // 第二列 批次
                    const _row_3 = this.batchArr2[rowIndex];
                    const _col_3 = _row_3 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_3,
                        colspan: _col_3,
                    };
                    // 第三列 支付方式
                } else if (columnIndex === 4) {
                    const _row_5 = this.paymentMethodArr2[rowIndex];
                    const _col_5 = _row_5 > 0 ? 1 : 0;
                    return {
                        rowspan: _row_5,
                        colspan: _col_5,
                    };
                }
                else if (rowIndex === len) {
                    return {
                        rowspan: 1,
                        colspan: 1
                    }
                }
            },
            
            //************按钮事件************
            //导出表格
            exportExcel() {
                ///如果已选择，则导出已选择数据，否则导出全部
                let row = this.currentRow;
                this.$confirm("确认导出吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    let para = {

                    };
                    exportStatistics(para).then((res) => {
                        console.log(res);
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });

                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: "错误",
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                }).catch(() => { console.log("error"); });
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
