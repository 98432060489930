<template>
    <section>
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入促销名称" @change="inputChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="sort" label="排序" width="50"></el-table-column>
            <el-table-column prop="name" label="促销名称" width=""></el-table-column>
            <el-table-column prop="sort" label="权重" width="100"></el-table-column>
            <el-table-column prop="maxRecevieNums" label="总数量" width="100"></el-table-column>
            <el-table-column prop="maxNums" label="每人限领" width="100"></el-table-column>
            <el-table-column prop="startTime" label="开始时间" width=""></el-table-column>
            <el-table-column prop="endTime" label="结束时间" width=""></el-table-column>
            <el-table-column prop="effectiveDays" label="有效天数" width="80"></el-table-column>
            <el-table-column prop="effectiveHours" label="有效小时" width="80"></el-table-column>
            <!--是否开启-->
            <el-table-column prop="isEnable" label="是否开启" width="100">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.isEnable ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.isEnable ? "开启" : "未开启" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--<el-table-column fixed="right"
                         label="操作"
                         width="100">
            <template slot-scope="scope">

                <el-form-item v-bind:key="item.id">-->
            <!-- 这里触发点击事件 -->
            <!--<el-button :type="item.func&&(item.func.toLowerCase().indexOf('handledel')!= -1 ||item.func.toLowerCase().indexOf('stop')!= -1 )? 'danger':'primary'" v-if="!item.isHide" @click="callFunc(item)">{{item.name}}</el-button>
                </el-form-item>


                <el-button  v-for="item in buttonList"  @click="callFunc(item)" @click="handleClick(scope.row)" v-if="!item.isHide" type="text" size="small">查看</el-button>
                <el-button type="text" size="small">编辑</el-button>
            </template>
        </el-table-column>-->
        </el-table>
        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--新增界面-->
        <el-dialog title="新增数据"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="100px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="优惠券名称" prop="name">
                    <el-input v-model="addForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否开启" prop="isEnable">
                    <el-switch v-model="addForm.isEnable"></el-switch>
                </el-form-item>
                <el-form-item label="权重" prop="sort">
                    <el-input v-model="addForm.sort" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="总数量" prop="maxRecevieNums">
                    <el-input v-model="addForm.maxRecevieNums" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">设置能领取的总数量，不管是否被兑换，将停止再被领取（0为不限制）</span>
                </el-form-item>
                <el-form-item label="每人限领数量" prop="maxNums">
                    <el-input v-model="addForm.maxNums" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">0为不限制</span>
                </el-form-item>
                <el-form-item label="用户领取" prop="isAutoReceive">
                    <el-switch v-model="addForm.isAutoReceive"></el-switch>
                    <span style="margin-left: 10px">启用后，用户可在前台直接领取</span>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker v-model="addForm.startTime"
                                    type="datetime"
                                    placeholder="选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker v-model="addForm.endTime"
                                    type="datetime"
                                    placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="有效天数" prop="effectiveDays">
                    <el-input v-model="addForm.effectiveDays" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="有效小时" prop="effectiveHours">
                    <el-input v-model="addForm.effectiveHours" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="优惠券名称" prop="name">
                    <el-input v-model="editForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否开启" prop="isEnable">
                    <el-switch v-model="editForm.isEnable"></el-switch>
                </el-form-item>
                <el-form-item label="权重" prop="sort">
                    <el-input v-model="editForm.sort" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="总数量" prop="maxRecevieNums">
                    <el-input v-model="editForm.maxRecevieNums" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">设置能领取的总数量，不管是否被兑换，将停止再被领取（0为不限制）</span>
                </el-form-item>
                <el-form-item label="每人限领数量" prop="maxNums">
                    <el-input v-model="editForm.maxNums" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">0为不限制</span>
                </el-form-item>
                <el-form-item label="用户领取" prop="isAutoReceive">
                    <el-switch v-model="editForm.isAutoReceive"></el-switch>
                    <span style="margin-left: 10px">启用后，用户可在前台直接领取</span>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker v-model="editForm.startTime"
                                    type="datetime"
                                    placeholder="选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker v-model="editForm.endTime"
                                    type="datetime"
                                    placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="有效天数" prop="effectiveDays">
                    <el-input v-model="editForm.effectiveDays" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="有效小时" prop="effectiveHours">
                    <el-input v-model="editForm.effectiveHours" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="促销条件">
                    <el-button @click="handleCondition">添加条件</el-button>
                    <template>
                        <el-table :data="conditions" style="width: 100%">
                            <el-table-column prop="code" label="条件代码" width="180"></el-table-column>
                            <el-table-column prop="title" label="条件名称" width="180"></el-table-column>
                            <el-table-column prop="parameters" label="参数"></el-table-column>
                            <el-table-column fixed="right"
                                             label="操作"
                                             width="100">
                                <template slot-scope="scope">
                                    <!--<el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>-->
                                    <el-button @click="deleteCondition(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form-item>
                <el-form-item label="促销结果">
                    <el-button @click="handleResult">添加结果</el-button>
                    <template>
                        <el-table :data="results" style="width: 100%">
                            <el-table-column prop="code" label="结果代码" width="180"></el-table-column>
                            <el-table-column prop="title" label="结果名称" width="180"></el-table-column>
                            <el-table-column prop="parameters" label="参数"></el-table-column>
                            <el-table-column fixed="right"
                                             label="操作"
                                             width="100">
                                <template slot-scope="scope">
                                    <el-button @click="deleteResult(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--促销条件-->
        <el-dialog title="编辑数据"
                   :visible.sync="conditionDialogVisible"
                   width="30%"
                   center>
            <el-form :model="conditionForm"
                     label-width="120px"
                     :rules="conditionFormRules"
                     ref="conditionForm">
                <el-form-item label="促销条件">
                    <el-select v-model="conditionForm.code" placeholder="请选择促销条件">
                        <el-option v-for="item in promotionConditionTypes"
                                   :label="item.sDescription"
                                   :value="item.sKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <template v-if="conditionForm.code =='GOODS_ALL'">
                    <el-form-item label="条件名称">
                        <span>所有商品都满足条件</span>
                    </el-form-item>
                    <el-form-item label="条件">
                        <span>无需设置任何条件，直接点击“完成”吧</span>
                    </el-form-item>
                </template>
                <template v-else-if="conditionForm.code =='GOODS_IDS'">
                    <el-form-item label="条件名称">
                        <span>指定商品id</span>
                    </el-form-item>
                    <el-form-item label="条件">
                        <el-button type="success" @click.native="handleSelectGoods">选择商品</el-button>
                        <ul v-if="selectedGoods">
                            <li v-for="goods in selectedGoods">
                                <span onclick="delSelectGoods(goods.id)">删除图标</span>
                                <span>{{ goods.name }}</span>
                            </li>
                        </ul>
                    </el-form-item>
                    <el-form-item label="商品数量">
                        <el-input-number v-model="conditionForm.nums" :min="1" label="商品数量"></el-input-number>
                    </el-form-item>
                </template>
                <template v-else-if="conditionForm.code =='SERVICES_IDS'">
                    <el-form-item label="条件名称">
                        <span>指定服务id</span>
                    </el-form-item>
                    <el-form-item label="条件">
                        <el-button type="success" @click.native="handleSelectServices">选择服务</el-button>
                        <ul v-if="selectedServices">
                            <li v-for="services in selectedServices">
                                <span onclick="delSelectServices(services.id)">删除图标</span>
                                <span>{{ services.title }}</span>
                            </li>
                        </ul>
                    </el-form-item>
                    <el-form-item label="服务数量">
                        <!--<el-input v-model="conditionForm.nums" placeholder="商品数量" type="number"></el-input>-->
                        <el-input-number v-model="conditionForm.nums" :min="1" label="商品数量"></el-input-number>
                    </el-form-item>
                </template>
                <template v-else-if="conditionForm.code =='ORDER_FULL'">
                    <el-form-item label="条件名称">
                        <span>满减</span>
                    </el-form-item>
                    <el-form-item label="满多少">
                        <el-input class="input_inline" v-model="conditionForm.money" placeholder="金额" type="number"></el-input>
                        <span>订单金额满多少的时候，优惠</span>
                    </el-form-item>
                </template>
                <template v-else-if="conditionForm.code =='USER_GRADE'">
                    <el-form-item label="条件名称">
                        <span>指定用户类型</span>
                    </el-form-item>
                    <el-form-item label="*请选择">
                        <el-select v-model="conditionForm.users" multiple placeholder="请选择用户类型">
                            <el-option v-for="item in userTypes"
                                       :key="item.sValue"
                                       :label="item.sDescription"
                                       :value="item.sKey">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="conditionDialogVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="conditionSubmit"
                           :loading="conditionLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--促销结果-->
        <el-dialog title="编辑数据"
                   :visible.sync="resultDialogVisible"
                   width="30%"
                   center>
            <el-form :model="resultForm"
                     label-width="80px"
                     :rules="resultFormRules"
                     ref="resultForm">
                <el-form-item label="促销结果">
                    <el-select v-model="resultForm.code" placeholder="请选择促销结果">
                        <el-option v-for="item in promotionResultTypes"
                                   :label="item.sDescription"
                                   :value="item.sKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <template v-if="resultForm.code =='GOODS_REDUCE'">
                    <el-form-item label="结果名称">
                        <span>指定商品减固定金额</span>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input class="input_inline" v-model="resultForm.money" placeholder="金额" type="number"></el-input>
                        <span>商品优惠的金额</span>
                    </el-form-item>
                </template>
                <template v-else-if="resultForm.code =='GOODS_DISCOUNT'">
                    <el-form-item label="结果名称">
                        <span>指定商品打X折</span>
                    </el-form-item>
                    <el-form-item label="折扣">
                        <el-input class="input_inline" v-model="resultForm.discount" placeholder="折扣" type="number"></el-input>
                        <span>大于0小于10的数字</span>
                    </el-form-item>
                </template>
                <template v-else-if="resultForm.code =='GOODS_ONE_PRICE'">
                    <el-form-item label="结果名称">
                        <span>指定商品一口价</span>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input class="input_inline" v-model="resultForm.money" placeholder="金额" type="number"></el-input>
                        <span>商品的固定价格</span>
                    </el-form-item>
                </template>
                <template v-else-if="resultForm.code =='ORDER_REDUCE'">
                    <el-form-item label="结果名称">
                        <span>订单减指定金额</span>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input class="input_inline" v-model="resultForm.money" placeholder="金额" type="number"></el-input>
                        <span>订单总价减XXX钱</span>
                    </el-form-item>
                </template>
                <template v-else-if="resultForm.code =='ORDER_DISCOUNT'">
                    <el-form-item label="促销结果">
                        <span>订单打X折</span>
                    </el-form-item>
                    <el-form-item label="结果名称">
                        <span>订单打X折</span>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input class="input_inline" v-model="resultForm.discount" placeholder="金额" type="number"></el-input>
                        <span>大于0小于10的数字</span>
                    </el-form-item>
                </template>
                <template v-else-if="conditionForm.code =='GOODS_HALF_PRICE'">
                    <el-form-item label="结果名称">
                        <span>指定商品每第几件减指定金额</span>
                    </el-form-item>
                    <el-form-item label="第几件">
                        <el-input-number v-model="resultForm.nums" class="input_inline" :min="1"></el-input-number>
                        <span>每第几件商品</span>
                    </el-form-item>
                    <el-form-item label="优惠金额">
                        <el-input-number v-model="resultForm.money" class="input_inline" placeholder="金额"></el-input-number>
                        <span>减去的固定价格</span>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="resultDialogVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="resultSubmit"
                           :loading="resultLoading">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择商品"
                   :visible.sync="selectGoodsDialogVisible"
                   width="60%"
                   center>
            <div style="text-align:right">
                <el-button @click.native="selectConfirmBtn">确定</el-button>
            </div>
            <!--列表-->
            <el-table :data="selectGoodsList"
                      highlight-current-row
                      v-loading="selectGoodsLoading"
                      @selection-change="selectGoodsSelsChange"
                      style="width: 100%">
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column prop="id" label="ID" width="" sortable></el-table-column>
                <el-table-column prop="name" label="名称" width="" sortable></el-table-column>
                <el-table-column prop="price" label="价格" width="" sortable></el-table-column>
                <el-table-column prop="stock" label="库存" width="" sortable></el-table-column>
                <el-table-column prop="goodsTypes" label="类型" width="" sortable></el-table-column>
            </el-table>
            <!--工具条-->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="prev, pager, next"
                               @current-change="handleCurrentChangeForSelectGoods"
                               :page-size="limit_goods"
                               :total="total_goods"
                               style="float: right">
                </el-pagination>
            </el-col>
            <!--<goodscomp @callFunction="callFunction"></goodscomp>-->

        </el-dialog>

        <el-dialog title="选择服务"
                   :visible.sync="selectServicesDialogVisible"
                   width="60%"
                   center>
            <div style="text-align:right">
                <el-button @click.native="selectServicesConfirmBtn">确定</el-button>
            </div>
            <!--列表-->
            <el-table :data="selectServicesList"
                      highlight-current-row
                      v-loading="selectServicesLoading"
                      @selection-change="selectServicesSelsChange"
                      style="width: 100%">
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column prop="id" label="ID" width="" sortable></el-table-column>
                <el-table-column prop="title" label="名称" width="" sortable></el-table-column>
                <el-table-column prop="money" label="价格" width="" sortable></el-table-column>
                <el-table-column prop="amount" label="库存" width="" sortable></el-table-column>
                <el-table-column label="类型" width="" sortable>服务</el-table-column>
            </el-table>
            <!--工具条-->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="prev, pager, next"
                               @current-change="handleCurrentChangeForSelectServices"
                               :page-size="limit_services"
                               :total="total_services"
                               style="float: right">
                </el-pagination>
            </el-col>
        </el-dialog>

        <el-dialog title="券码列表"
                   :visible.sync="couponDialogVisible"
                   width="60%"
                   center>
            <!--列表-->
            <el-table :data="couponlist"
                      highlight-current-row
                      v-loading="couponLoading"
                      style="width: 100%">
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column prop="id" label="序列" width=""></el-table-column>
                <el-table-column prop="couponCode" label="优惠券编码" width=""></el-table-column>
                <el-table-column prop="isUsed" label="是否使用" width="">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isUsed"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="userId" label="领取者" width=""></el-table-column>
                <el-table-column prop="createTime" label="生成时间" width=""></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width=""></el-table-column>
            </el-table>
            <!--工具条-->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="prev, pager, next"
                               @current-change="handleCurrentChangeForCoupon"
                               :page-size="limit"
                               :total="total_coupon"
                               style="float: right">
                </el-pagination>
            </el-col>
        </el-dialog>


        <!--指定用户发券界面-->
        <el-dialog title="指定用户发券界面"
                   :visible.sync="giftCouponFormVisible"
                   v-model="giftCouponFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="giftCouponForm"
                     label-width="80px"
                     :rules="giftCouponFormRules"
                     ref="giftCouponForm">
                <el-form-item label="用户ID" prop="name">
                    <el-input v-model="giftCouponForm.data" auto-complete="off"></el-input>
                    <span>每个用户ID之间用逗号','间隔</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="giftCouponFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="giftCouponSubmit"
                           :loading="giftCouponLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getPromotionListPage,
        getCouponListPage,
        addPromotion,
        editPromotion,
        getPromotion,
        getConditionCreate,
        getGoodsListPage,
        doConditionCreate,
        getConditionList,
        doResultCreate,
        getResultList,
        doConditionDelete,
        getServicesListPage,
        doResultDelete,
        getResultCreate,
        doGiftCoupon,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    //import GoodsComp from "../../../../Promotion/components/GoodsComp";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "", //双向绑定搜索内容
                    state: '',
                    type: '',
                    picker_start: '',
                    picker_end: '',
                },
                //列表数据
                datalist: [],
                roles: [],
                total: 0,
                page: 1,
                limit: 20,
                buttonList: [],
                currentRow: null,
                listLoading: false,
                sels: [], //列表选中列

                //新增界面配置信息
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {},
                addForm: {},//新增界面数据

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {},
                editForm: {},//编辑界面数据


                //指定用户发券界面配置信息
                giftCouponFormVisible: false, //编辑界面是否显示
                giftCouponLoading: false,
                giftCouponFormRules: {},
                giftCouponForm: {},//编辑界面数据

                //优惠券码列表界面
                couponlist: [],
                couponDialogVisible: false, //编辑界面是否显示
                couponLoading: false,
                total_coupon: 0,
                page_coupon: 1,


                //促销条件界面配置信息
                conditionDialogVisible: false, //界面是否显示
                conditionLoading: false,
                conditionFormRules: {},
                conditionForm: {
                    promotionId: 0,
                    title: "",
                    code: "",
                    goods: "",
                    services: "",
                    users: "",
                    money: 0,
                    nums: 1,
                },//界面数据

                //促销结果界面配置信息
                resultDialogVisible: false, //界面是否显示
                resultLoading: false,
                resultFormRules: {},
                resultForm: {
                    promotionId: 0,
                    title: "",
                    code: "",
                    money: 0,
                    discount: 0,
                    nums: 1,
                },//界面数据


                //选择商品页面
                selectGoodsDialogVisible: false, //界面是否显示
                selectGoodsList: [],
                selectGoodsLoading: false,
                page_goods: 1,
                limit_goods: 20,
                total_goods: 0,
                goodsSels: [],
                selectedGoods: [],

                //选择服务页面
                selectServicesDialogVisible: false, //界面是否显示
                selectServicesList: [],
                selectServicesLoading: false,
                page_services: 1,
                limit_services: 20,
                total_services: 0,
                servicesSels: [],
                selectedServices: [],

                //促销条件
                conditions: [],
                results: [],
                promotionConditionTypes: [],
                promotionResultTypes: [],
                userTypes: [],
            };
        },
        methods: {
            inputChange(val) {
                this.filters.name = val;
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            //列表多选
            selsChange: function (sels) {
                console.log(sels);
                this.sels = sels;
            },
            //弹窗商品列表多选
            selectGoodsSelsChange: function (sels) {
                this.goodsSels = sels;
            },
            //弹窗服务列表多选
            selectServicesSelsChange: function (sels) {
                this.servicesSels = sels;
            },
            //列表页面操作
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
            //优惠券码列表操作
            handleCurrentChangeForCoupon(val) {
                this.page_coupon = val;
                this.getCouponListPage();
            },
            //************弹层操作************
            //弹层商品列表操作
            handleCurrentChangeForSelectGoods(val) {
                this.page_goods = val;
                this.getGoodsListPage();
            },
            //弹层服务列表操作
            handleCurrentChangeForSelectServices(val) {
                this.page_goods = val;
                this.getServicesListPage();
            },
            //************获取列表************
            //获取优惠券列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    name: this.filters.name,
                    types: 2,
                    orderField: "sort",
                    orderDirection: "asc",
                };
                this.listLoading = true;
                getPromotionListPage(para).then((res) => {
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },
            //获取优惠券码列表
            getCouponListPage() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的数据！",
                        type: "error",
                    });
                    that.couponDialogVisible = false;
                    return;
                }
                let para = {
                    page: this.page_coupon,
                    limit: this.limit,
                    promotionId: row.id,
                    orderField: "sort",
                    orderDirection: "asc",
                };
                this.couponLoading = true;
                getCouponListPage(para).then((res) => {
                    console.log(res);
                    that.total_coupon = res.data.count;
                    that.couponlist = res.data.data;
                    that.couponLoading = false;
                });
            },
            //获取商品列表
            getGoodsListPage() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    //types: 2,
                    orderField: "sort",
                    orderDirection: "asc",
                };
                this.selectGoodsLoading = true;
                getGoodsListPage(para).then((res) => {
                    console.log(res);
                    this.total_goods = res.data.count;
                    this.selectGoodsList = res.data.data;
                    this.selectGoodsLoading = false;
                });
            },
            //获取服务列表
            getServicesListPage() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    //types: 2,
                    orderField: "sort",
                    orderDirection: "asc",
                };
                this.selectServicesLoading = true;
                getServicesListPage(para).then((res) => {
                    console.log(res);
                    this.total_services = res.data.count;
                    this.selectServicesList = res.data.data;
                    this.selectServicesLoading = false;
                });
            },
            //************按钮事件************
            //显示新增界面
            handleAdd() {
                let that = this;

                that.addFormVisible = true;
                that.addLoading = false;
            },
            //显示编辑界面
            handleEdit() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的数据！",
                        type: "error",
                    });
                    return;
                }
                ///获取信息
                getPromotion({ id: row.id }).then((res) => {
                    if (res.code == 0) {
                        that.editForm = res.data.model;
                        that.conditions = res.data.conditions;
                        that.results = res.data.results;
                    }
                });
                this.editLoading = false;
                that.editFormVisible = true;
            },
            //显示券码列表界面
            handleCoupon() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的数据！",
                        type: "error",
                    });
                    return;
                }
                that.couponDialogVisible = true;
                that.getCouponListPage();
            },
            //显示添加促销条件界面
            handleCondition() {
                let that = this;
                getConditionCreate({ id: this.editForm.id }).then((res) => {
                    if (res.code == 0) {
                        that.promotionConditionTypes = res.data.promotionConditionTypes;
                        that.userTypes = res.data.userTypes;
                    }
                    else {
                        this.$message({
                            message: "",
                            type: "warning"
                        })
                    }
                });
                this.conditionForm.promotionId = this.editForm.id;
                this.conditionDialogVisible = true;
            },
            //显示添加促销结果界面
            handleResult() {
                this.resultForm.promotionId = this.editForm.id;
                let that = this;
                getResultCreate({ id: this.editForm.id }).then((res) => {
                    if (res.code == 0) {
                        this.resultDialogVisible = true;
                        that.promotionResultTypes = res.data.resultCodes;
                    }
                    else {
                        this.$message({
                            message: res.msg,
                            type: "warning"
                        })
                    }
                });
            },
            //显示选择商品页面（促销条件）
            handleSelectGoods() {
                this.getGoodsListPage();
                this.selectGoodsDialogVisible = true;
            },
            //显示选择服务页面（促销条件）
            handleSelectServices() {
                this.getServicesListPage();
                this.selectServicesDialogVisible = true;
            },
            //显示指定用户发券界面
            handleGiftCoupon() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要操作的数据！",
                        type: "error",
                    });
                    return;
                }
                that.giftCouponForm.id = row.id;
                that.giftCouponFormVisible = true;
                that.giftCouponLoading = false;
            },
            //************提交方法************
            //新增优惠券
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            let para = Object.assign({}, this.addForm);
                            addPromotion(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.getList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.addFormVisible = false;
                            });
                        });
                    }
                });
            },
            //编辑优惠券
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let para = Object.assign({}, this.editForm);
                            editPromotion(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.getList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.editFormVisible = false;
                            });
                        });
                    }
                });
            },
            //新增促销条件
            conditionSubmit: function () {
                this.$refs.conditionForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.conditionLoading = true;
                            //Object.assign({}, this.conditionForm)
                            let formData = this.conditionForm
                            let para = {};
                            para.promotionId = formData.promotionId;
                            para.code = formData.code;
                            var parameters = {};
                            if (formData.code === 'GOODS_ALL') {//所有商品满足条件
                                //parameters.nums = parseInt(formData.nums);
                            }
                            else if (formData.code === 'GOODS_IDS') { //指定某些商品满足条件
                                if (!!!formData.goods) {
                                    this.$message({
                                        message: "请选择商品",
                                        type: "warning",
                                    });
                                    this.conditionLoading = false;
                                    return false;
                                }
                                if (formData.nums < 1) {
                                    this.$message({
                                        message: "商品数量要大于0",
                                        type: "warning",
                                    });
                                    this.conditionLoading = false;
                                    return false;
                                }
                                parameters.goodsId = formData.goods;
                                parameters.nums = formData.nums;
                            }
                            else if (formData.code === 'SERVICES_IDS') { //指定某些服务满足条件
                                if (!!!formData.services) {
                                    this.$message({
                                        message: "请选择服务",
                                        type: "warning",
                                    });
                                    this.conditionLoading = false;
                                    return false;
                                }
                                if (formData.nums < 1) {
                                    this.$message({
                                        message: "服务数量要大于1",
                                        type: "warning",
                                    });
                                    this.conditionLoading = false;
                                    return false;
                                }
                                parameters.servicesId = formData.services;
                                parameters.nums = formData.nums;
                            }
                            else if (formData.code === 'ORDER_FULL') {//订单满XX金额满足条件
                                if (!!!formData.money) {
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    this.conditionLoading = false;
                                    return false;
                                }
                                if (formData.money < 0.01) {
                                    this.$message({
                                        message: "金额最少为0.01",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.money = formData.money;
                            } else if (formData.code === 'USER_GRADE') {//用户符合指定等级
                                if (!!!formData.users) {
                                    this.$message({
                                        message: "请选择用户类型",
                                        type: "warning",
                                    });
                                    this.conditionLoading = false;
                                    return false;
                                }
                                parameters.users = formData.users;
                            }
                            para.parameters = JSON.stringify(parameters);
                            doConditionCreate(para).then((res) => {
                                if (res.code == 0) {
                                    this.conditionDialogVisible = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["conditionForm"].resetFields();
                                    this.selectedGoods = [];
                                    this.selectedServices = [];
                                    //获取条件列表
                                    this.getConditionList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                            this.conditionLoading = false;
                        });
                    }
                });
            },
            //新增促销结果
            resultSubmit: function () {
                this.$refs.resultForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.resultLoading = true;
                            let formData = this.resultForm
                            let para = {};
                            para.promotionId = formData.promotionId;
                            para.code = formData.code;
                            var parameters = {};
                            if (formData.code === 'GOODS_REDUCE') {//指定商品减固定金额
                                if (!!!formData.money) {
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.money = formData.money;
                            } else if (formData.code === 'GOODS_DISCOUNT') { //指定商品打X折
                                if (!!!formData.discount) {
                                    layer.msg("请输入折扣");
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.discount = formData.discount;
                            } else if (formData.code === 'GOODS_ONE_PRICE') {//指定商品一口价
                                if (!!!formData.money) {
                                    layer.msg("请输入金额");
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.money = formData.money;
                            } else if (formData.code === 'ORDER_REDUCE') {//订单减指定金额
                                if (!!!formData.money) {
                                    layer.msg("请输入金额");
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.money = formData.money;
                            } else if (formData.code === 'ORDER_DISCOUNT') {//订单打X折
                                if (!!!formData.discount) {
                                    layer.msg("请输入折扣");
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.discount = formData.discount;
                            } else if (formData.code === 'GOODS_HALF_PRICE') {//指定商品每第几件减指定金额
                                if (!!!formData.num) {
                                    layer.msg("请输入第几件");
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.num = formData.num;
                                if (!!!formData.money) {
                                    layer.msg("请输入金额");
                                    this.$message({
                                        message: "请输入金额",
                                        type: "warning",
                                    });
                                    return false;
                                }
                                parameters.money = formData.money;
                            }
                            para.parameters = JSON.stringify(parameters);
                            console.log(para);
                            doResultCreate(para).then((res) => {
                                if (res.code == 0) {
                                    this.resultDialogVisible = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["resultForm"].resetFields();
                                    //获取促销结果列表
                                    this.getResultList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.resultLoading = false;
                            });
                        });
                    }
                });
            },
            //选择商品提交
            selectConfirmBtn: function () {
                this.selectedGoods = this.goodsSels;
                let arr = [];
                if (this.goodsSels) {
                    for (var i = 0; i < this.goodsSels.length; i++) {
                        arr.push(this.goodsSels[i].id);
                    }
                };
                this.conditionForm.goods = arr.join();
                this.goodsSels = [];
                this.selectGoodsDialogVisible = false;
            },
            //选择服务提交
            selectServicesConfirmBtn: function () {
                this.selectedServices = this.servicesSels;
                let arr = [];
                if (this.selectedServices) {
                    for (var i = 0; i < this.selectedServices.length; i++) {
                        arr.push(this.selectedServices[i].id);
                    }
                };
                this.conditionForm.services = arr.join();
                this.servicesSels = [];
                this.selectServicesDialogVisible = false;
            },
            //获取促销条件列表
            getConditionList() {
                console.log("获取促销条件列表");
                let that = this;
                getConditionList({ id: this.editForm.id }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        that.conditions = res.data;
                    }
                });
                this.getConditionListLoading = false;
            },
            //获取促销结果列表
            getResultList() {
                console.log("获取促销结果列表");
                let that = this;
                getResultList({ id: this.editForm.id }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        that.results = res.data;
                    }
                });
                this.getResultListLoading = false;
            },
            //指定用户发券提交
            giftCouponSubmit: function () {
                this.$refs.giftCouponForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认给指定用户发券吗？", "提示", {}).then(() => {
                            this.giftCouponLoading = true;
                            let formData = this.giftCouponForm
                            let para = {};
                            para.id = formData.id;
                            para.data = formData.data;
                            var parameters = {};
                            para.parameters = JSON.stringify(parameters);
                            console.log(para);
                            doGiftCoupon(para).then((res) => {
                                if (res.code == 0) {
                                    this.giftCouponDialogVisible = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["giftCouponForm"].giftCouponFields();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.giftCouponLoading = false;
                            });
                        });
                    }
                });
            },
            //删除已选商品
            delSelectGoods: function (id) {
                console.log("点击了删除...");
                let _selectedGoods = this.selectedGoods;
                for (var i = 0; i < _selectedGoods.length; i++) {
                    if (_selectedGoods[i].id == id) {
                        this.selectedGoods.remove(_selectedGoods[i].id);
                    }
                }
            },
            //删除促销条件
            deleteCondition: function (row) {
                this.$confirm("确定要删除吗", "删除提示", {}).then(() => {
                    doConditionDelete({ id: row.id }).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "删除成功",
                                type: "success",

                            })
                            this.getConditionList();
                        }
                        else {
                            this.$message({
                                message: res.msg,
                                type: "warning",
                            })
                        }
                    });
                });
            },
            //删除促销结果
            deleteResult: function (row) {
                this.$confirm("确定要删除吗", "删除提示", {}).then(() => {
                    doResultDelete({ id: row.id }).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "删除成功",
                                type: "success",

                            })
                            this.getResultList();
                        }
                        else {
                            this.$message({
                                message: res.msg,
                                type: "warning",
                            })
                        }
                    });
                });
            }
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            console.log(this.$route.path);
            console.log(routers);
            this.buttonList = getButtonList(this.$route.path, routers);
            console.log(this.buttonList);
        },
    };
</script>

<style scoped>
    .input_inline {
        display: inline-block;
        width: 120px;
        margin-right: 10px;
    }
</style>
