<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入超市名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectStateChange" @clear="selectStateClear" clearable>
                    <el-option v-for="item in select_State_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="block">
                    <el-date-picker v-model="filters.picker_end"
                                    type="daterange"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="datePickeChange"
                                    :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
            <el-table-column prop="title" label="标题" width="" sortable></el-table-column>
            <el-table-column prop="brief" label="描述" width="" sortable></el-table-column>
            <el-table-column prop="orderNumber" label="商品信息" width="350">
                <template slot-scope="scope">
                    <table>
                        <tr>
                            <th width="100">商品</th>
                            <th width="60">售价</th>
                            <th width="60">原价</th>
                            <th width="60">进价</th>
                        </tr>
                        <tr v-for="(item,i) in scope.row.goodslist">
                            <td>{{ item.name }}</td>
                            <td>¥{{ item.price }}</td>
                            <td>¥{{ item.oprice }}</td>
                            <td>¥{{ item.pprice }}</td>
                        </tr>
                    </table>
                </template>
            </el-table-column>
            <!--<el-table-column prop="orderNumber" label="订单数量" width="" sortable></el-table-column>-->
            <el-table-column prop="orderNumber" label="订单" width="280">
                <template slot-scope="scope">
                    <table v-for="(order,i) in scope.row.orderlist" style="border:1px solid solid">
                        <thead>
                            <tr>
                                <th colspan="2">用户:{{ order.userName }}</th>
                                <th colspan="1">状态:{{ order.stateText }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,i) in order.items">
                                <td width="100">{{ item.name }}</td>
                                <td width="80">¥{{ item.price }}</td>
                                <td width="80" style="text-align:center">*{{ item.nums }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </el-table-column>
            <el-table-column prop="storeName" label="超市名称" width="" sortable></el-table-column>
            <el-table-column prop="state" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 1 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.state == 1 ? "正在进行" : scope.row.state == 2 ? "已结束" :scope.row.state == 3 ? "已取消" : "异常" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column fixed="right"
                             label="操作"
                             width="100">
                <template slot-scope="scope">
                    <!--<el-button @click="handleClick(scope.row)" type="text" size="small" style="margin-left:10px">发货</el-button>
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
            <el-button @click="handleClick(scope.row)" type="text" size="small">取消订单</el-button>-->
                </template>
            </el-table-column>
            <!--<el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>-->
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
    import {
        getRoleListPage,
        getJieLongListPage,
        exportJieLong,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_State_options: [{
                    value: '1',
                    label: '正在进行'
                }, {
                    value: '2',
                    label: '已结束'
                }, {
                    value: '3',
                    label: '已取消'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                filters: {
                    storeName: "", //双向绑定搜索内容
                    state: '',
                    payState: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    //storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据
            };
        },
        methods: {
            handleClick(row) {
                console.log(row);
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            nameChange(val) {
                this.filters.storeName = val;
            },
            selectStateChange(val) {
                this.filters.state = val;
            },
            selectStateClear() {
                this.filters.state = "";
            },
            datePickeChange(val) {
                if (val == null) {
                    console.log("null");
                    this.filters.startTime = "";
                    this.filters.endTime = "";
                }
                else if (val.length == 2) {
                    console.log("success");
                    this.filters.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate();
                    this.filters.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                    //this.filters.startTime = val[0];
                    //this.filters.endTime = val[1];
                }
            },
            //获取列表
            getDataList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    storeName: this.filters.storeName,
                    state: this.filters.state,
                    payState: this.filters.payState,
                    startTime: this.filters.startTime,
                    endTime: this.filters.endTime,
                };
                this.listLoading = true;
                getJieLongListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //导出表格
            exportExcel() {
                let row = this.currentRow;
                //if (!row) {
                //    this.$message({
                //        message: "请选择要导出的店铺！",
                //        type: "error",
                //    });
                //    return;
                //}
                this.$confirm("确认导出吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    //let para = { id: row.id };
                    exportJieLong({}).then((res) => {
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            console.log(res.data);
                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },

            //************提交方法************


            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getDataList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
