<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入超市名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.orderNum" placeholder="请输入订单号" @change="orderNumChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.type" placeholder="订单类型" @change="selectTypeChange" @clear="selectTypeClear" clearable>
                    <el-option v-for="item in select_type_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.payState" placeholder="支付状态" @change="selectPayStateChange" @clear="selectPayStateClear" clearable>
                    <el-option v-for="item in select_payState_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="block">
                    <el-date-picker v-model="filters.picker_end"
                                    type="daterange"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="datePickeChange"
                                    :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <el-tag type="success">今日汇总</el-tag>
        <el-table :data="tableData"
                  style="width: 45%">
            <el-table-column prop="storeNum"
                             label="店铺数量"
                             width="180">
            </el-table-column>
            <el-table-column prop="totalNum"
                             label="商品数量"
                             width="180">
            </el-table-column>
            <el-table-column prop="orderAmount"
                             label="订单金额">
            </el-table-column>
            <el-table-column prop="payedAmount"
                             label="支付金额">
            </el-table-column>
        </el-table>

        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="orderNumber" label="商品信息" width="350">
                <template slot-scope="scope">
                    <p><span>订单编号:{{ scope.row.orderNumber }}</span></p>
                    <p><span>下单时间：{{ scope.row.createTime }}</span></p>
                    <ul style="padding-inline-start: 0;" v-for="(item,i) in scope.row.items">
                        <li style="display:flex;">
                            <img :src="item.image" style="width:50px;height:50px;" />
                            <p style="">{{ item.name }}</p>
                            <p style="padding:0 10px;">￥{{ item.price }}</p>
                            <p>*{{ item.num }}</p>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column prop="userNickName" label="用户昵称"></el-table-column>
            <el-table-column prop="storeName" label="超市名称"></el-table-column>
            <el-table-column prop="storeNumber" label="超市编号"></el-table-column>
            <el-table-column prop="manager" label="负责人"></el-table-column>
            <el-table-column label="实付款(￥)" width="">
                <template slot-scope="scope">
                    <el-tag type="danger" disable-transitions>
                        ￥{{ scope.row.payedAmount }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="couponName" label="优惠券名称"></el-table-column>
            <el-table-column prop="couponDiscountAmount" label="优惠券金额"></el-table-column>
            <el-table-column label="收件人信息">
                <template slot-scope="scope">
                    <p style="margin-block-start: 0; margin-block-end: 0;">{{ scope.row.shipName }}</p>
                    <p style="margin-block-start: 0; margin-block-end: 0;">{{ scope.row.shipMobile }}</p>
                    <p style="margin-block-start: 0; margin-block-end: 0;">{{ scope.row.shipAddress }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="memo" label="买家备注"></el-table-column>
            <el-table-column prop="orderType" label="订单类型">
                <template slot-scope="scope">
                    <el-tag type="success" disable-transitions v-if="scope.row.orderType == 1">
                        普通订单
                    </el-tag>
                    <el-tag type="warning" disable-transitions v-else-if="scope.row.orderType == 2">
                        服务订单
                    </el-tag>
                    <el-tag type="danger" disable-transitions v-else-if="scope.row.orderType == 3">
                        接龙订单
                    </el-tag>
                    <el-tag type="success" disable-transitions v-else-if="scope.row.orderType == 6">
                        采购订单
                    </el-tag>
                    <el-tag type="success" disable-transitions v-else>
                        其他订单
                    </el-tag>
                    <!--<el-tag :type="scope.row.orderType == 1 ? 'warning' :scope.row.orderType == 2 ? 'success': 'danger'" disable-transitions>
                        {{ scope.row.orderType == 1 ? "普通订单" :scope.row.orderType == 2 ? "服务订单" : scope.row.orderType == 3 ? "接龙订单" : "其他订单" }}
                    </el-tag>-->
                </template>
            </el-table-column>

            <el-table-column prop="payment" label="支付方式"></el-table-column>
            <el-table-column prop="status" label="交易状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.payStatus == 2 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.payStatus == 2 ? "已支付" : scope.row.payStatus == 1?"未支付":"已退款" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--<el-table-column fixed="right"
                             label="操作"
                             width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small" style="margin-left:10px">发货</el-button>
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
                    <el-button @click="handleClick(scope.row)" type="text" size="small">取消订单</el-button>
                </template>
            </el-table-column>-->
            <!--<el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>-->
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--导入数据界面-->
        <el-dialog title="导入数据"
                   :visible.sync="importFormVisible"
                   v-model="importFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="importForm"
                     label-width="80px"
                     :rules="importFormRules"
                     ref="importForm">
                <el-form-item label="上传数据" prop="image">
                    <el-upload class="upload-demo"
                               action="/api/CoreCmsOrder/ImportOrder"
                               :headers="header"
                               multiple
                               :limit="1"
                               :on-remove="handleRemove"
                               :before-upload="beforeUpload"
                               :on-success="handleSuccess"
                               :file-list="fileList">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传xls文件，且不超过10M</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <!--<div slot="footer" class="dialog-footer">
                <el-button @click.native="importFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="importSubmit"
                           :loading="importLoading">导入数据</el-button>
            </div>-->
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getOrderListPage,
        exportOrdereList,
        importOrder,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    import store from "../../store";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_payState_options: [{
                    value: '1',
                    label: '未支付'
                }, {
                    value: '2',
                    label: '已支付'
                }, {
                    value: '5',
                    label: '已退款'
                }],
                select_type_options: [{
                    value: '1',
                    label: '普通订单'
                }, {
                    value: '2',
                    label: '服务订单'
                }, {
                    value: '3',
                    label: '接龙订单'
                }, {
                    value: '6',
                    label: '采购订单'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                filters: {
                    storeName: "", //双向绑定搜索内容
                    orderNum:'',
                    type: '',
                    payState: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                tableData: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                selsOrderNumber: [], //列表选中列订单号
                addDialogFormVisible: false,

                //上传界面配置信息
                importFormVisible: false, //上传界面是否显示
                importLoading: false,
                importFormRules: {
                    //storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                importForm: {},//编辑界面数据
                fileUrl: '',
                fileList: [],
                header: {},
            };
        },

        methods: {

            initheader() {
                var storeTemp = store;
                var curTime = new Date()
                var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
                if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
                    // 判断是否存在token，如果存在的话，则每个http header都加上token
                    //config.headers.Authorization = "Bearer " + storeTemp.state.token;
                    this.header.Authorization = "Bearer " + storeTemp.state.token;
                }
                console.log(this.header.Authorization);
            },


            beforeUpload(file) {
                //const isJPG = file.type === 'image/jpeg';
                //const isLt2M = file.size / 1024 / 1024 < 2;

                //if (!isJPG) {
                //    this.$message.error('上传头像图片只能是 JPG 格式!');
                //}
                //if (!isLt2M) {
                //    this.$message.error('上传头像图片大小不能超过 2MB!');
                //}
                //return isJPG && isLt2M;
            },
            handleRemove(file, fileList) {
                this.fileUrl = "";
            },
            handleSuccess(res, file) {
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
                ///文件上传成功
                this.fileUrl = res.data.src;
                console.log(res);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            handleClick(row) {
                console.log(row);
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getGoodss();
            },
            nameChange(val) {
                this.filters.storeName = val;
            },
            orderNumChange(val) {
                this.filters.orderNum = val;
            },
            selectTypeChange(val) {
                this.filters.type = val;
            },
            selectTypeClear() {
                this.filters.type = "";
            },
            selectPayStateChange(val) {
                this.filters.payState = val;
            },
            selectPayStateClear() {
                this.filters.payState = "";
            },
            datePickeChange(val) {
                if (val == null) {
                    console.log("null");
                    this.filters.startTime = "";
                    this.filters.endTime = "";
                }
                else if (val.length == 2) {
                    console.log("success");
                    this.filters.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate();
                    this.filters.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                    //this.filters.startTime = val[0];
                    //this.filters.endTime = val[1];
                }
            },
            //获取店铺列表
            getGoodss() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    storeName: this.filters.storeName,
                    orderNum: this.filters.orderNum,
                    type: this.filters.type,
                    payState: this.filters.payState,
                    startTime: this.filters.startTime,
                    endTime: this.filters.endTime,
                    orderField: 'createTime',
                };
                this.listLoading = true;
                getOrderListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.tableData = res.data.otherData;
                    console.log(this.tableData);
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //导出表格
            exportExcel() {
                let row = this.currentRow;
                //if (!row) {
                //    this.$message({
                //        message: "请选择要导出的店铺！",
                //        type: "error",
                //    });
                //    return;
                //}
                console.log(this.filters);
                this.$confirm("确认导出吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    let para = {
                        page: this.page,
                        limit: this.limit,
                        storeName: this.filters.storeName,
                        type: this.filters.type,
                        payState: this.filters.payState,
                        startTime: this.filters.startTime,
                        endTime: this.filters.endTime,
                    };
                    //let para = { id: row.id };
                    exportOrdereList(para).then((res) => {
                        console.log(res);
                        this.listLoading = false;
                        if (res.data.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            window.open(res.data.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },


            //显示导入数据界面
            handleImport() {
                let that = this;
                that.importFormVisible = true;
                that.importLoading = false;
            },

            //************提交方法************

            //导入
            importSubmit: function () {
                console.log("1111111111");
                //let para = { filePath: this.fileUrl };
                let para = {
                    filePath: "http://8.142.145.98:2021/upload/product/23af0ac2-9e2c-4d95-8559-b95b2751634b.xls",
                };
                console.log("222222222222");
                importOrder(para).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        this.importLoading = false;
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.$refs["importForm"].resetFields();
                        this.importFormVisible = false;
                        this.getGoodss();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
            },

            selsChange: function (sels) {
                this.sels = sels;
                this.selsOrderNumber = sels.map(item => item.orderNumber);
                console.log(this.selsOrderNumber)
            },
        },
        mounted() {
            this.getGoodss();
            this.initheader();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
