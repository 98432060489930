<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <div class="index">
            <!--<el-input type="text" id="place" placeholder="请输入地点" blur="search();" ></el-input>-->
            <div id="container"></div>
            <div id="panel"><input type="text" id="place" placeholder="输入地点" /></div>
        </div>
    </section>
</template>

<script>
    import {
        getRoleListPage,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    let map, marker, point, infoWindow, geolocation, ap, geocoder;
    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "",
                },
                position: {
                    longitude: 120.42472232792662,//经度
                    latitude: 36.1598468468997,//纬度
                    city: ''
                },
                address: "",//省市区
                addressInfo: "",//详细地址
                datalist: [],
                roles: [],
                categorys: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                listLoading: false,
                sels: [], //列表选中列
            };
        },
        methods: {
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            //位置信息在地图上展示
            setMapData() {
                var latitude = this.position.latitude;
                var longitude = this.position.longitude;
                point = new TMap.LatLng(latitude, longitude);
                // 初始化地图
                map = new TMap.Map('container', {
                    zoom: 17, // 设置地图缩放
                    center: point, // 设置地图中心点坐标，
                });
                this.createMarker();
                //监听点击事件添加marker
                map.on("click", (evt) => {
                    point = new TMap.LatLng(evt.latLng.lat, evt.latLng.lng);
                    var name = "";
                    if (evt.poi != null) {
                        name = evt.poi.name;
                    }
                    this.createMarker(name);
                });
                this.search();
            },
            createMarker(name) {
                var title = "";
                var address = "";
                if (marker == undefined) {
                    marker = new TMap.MultiMarker({
                        map: map,
                        styles: {
                            // 点标记样式
                            marker: new TMap.MarkerStyle({
                                width: 20, // 样式宽
                                height: 30, // 样式高
                                anchor: { x: 10, y: 30 }, // 描点位置
                                src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png', // 标记路径
                            }),
                        },
                        geometries: [
                            // 点标记数据数组
                            {
                                position: point,// 标记位置(经度，纬度，高度)
                            },
                        ],
                    });
                }
                else {
                    marker.setGeometries([]);
                    marker.add({
                        position: point
                    });
                }
                if (infoWindow == undefined) {
                    //初始化infoWindow
                    infoWindow = new TMap.InfoWindow({
                        map: map,
                        position: point,
                        offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
                    });
                }
                else {
                    infoWindow.setPosition(point);//设置信息窗位置
                }
                if (geocoder == undefined) {
                    geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
                }
                // 将给定的坐标位置转换为地址
                geocoder.getAddress({ location: point }).then((result) => {
                    if (result.status == 0) {
                        address = result.result.address;
                        if (name != "" && name != undefined) {
                            title = name;
                        }
                        else {
                            title = result.result.formatted_addresses.recommend;
                        }
                    }
                    infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address + '</p></div>');//设置信息窗内容
                });
            },
            getMyLocation() {
                geolocation = new qq.maps.Geolocation("IZNBZ-5DLCU-K2RVE-BRT7H-7EI5Z-DRBNC", "ufarmer");
                geolocation.getLocation(this.showPosition, this.showErr);
                this.showPosition
            },
            showPosition(position) {
                console.log(position);
                this.position.latitude = position.lat;
                this.position.longitude = position.lng;
                this.position.city = position.city;
                this.setMapData();
            },
            ///定位失败再请求定位，测试使用
            showErr() {
                console.log("定位失败，请重试！");
                this.setMapData();
            },

            /**搜索地点 */
            search() {
                ap = new window.qq.maps.place.Autocomplete(document.getElementById('place'));
                var keyword = "";
                //调用Poi检索类。用于进行本地检索、周边检索等服务。
                var searchService = new window.qq.maps.SearchService({
                    complete: function (results) {
                        console.log(results);
                        if (results.type === "CITY_LIST") {
                            searchService.setLocation(results.detail.cities[0].cityName);
                            searchService.search(keyword);
                            return;
                        }
                        var pois = results.detail.pois;
                        if (pois != undefined) {
                            var searchpoint = pois[0];
                            //修改地图中心点
                            point = new TMap.LatLng(searchpoint.latLng.lat, searchpoint.latLng.lng);
                            map.setCenter(point);
                            marker.setGeometries([]);
                            marker.add({
                                position: point
                            });
                            infoWindow.setPosition(point);//设置信息窗位置
                            var title = "";
                            var address = "";
                            if (searchpoint.name != undefined) {
                                title = searchpoint.name;
                            }
                            if (searchpoint.address != undefined) {
                                address = searchpoint.address;
                            }
                            infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address + '</p></div>');//设置信息窗内容
                        }
                    }
                });
                //添加监听事件
                window.qq.maps.event.addListener(ap, "confirm", function (res) {
                    keyword = res.value;
                    searchService.search(keyword);
                });
            }

        },
        mounted() {
            this.getMyLocation();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };

</script>
<style>
    #container {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .map_popinfo {
        text-align: left;
    }

        .map_popinfo .title {
            padding: 0;
            margin: 0;
            line-height: 18px;
            font-size: 18px;
            font-weight: 600;
        }

        .map_popinfo .address {
            font-size: 14px;
            padding: 5px 0;
            margin: 0;
            line-height: 24px;
        }

        .map_popinfo .confirm_btn {
            background: #409eff;
            color: #fff;
            padding: 5px 10px;
            border: none;
            border-radius: 5px;
        }

    #panel {
        position: absolute;
        background: #FFF;
        width: 350px;
        padding: 20px;
        z-index: 9999;
        top: 30px;
        left: 30px;
        border-radius: 5px;
    }

        #panel input {
            padding: 10px;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            width: 90%;
            font-size: 16px;
        }
</style>
