<template>
    <section>
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入商品名称" @change="inputChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="sort" label="排序" width=""></el-table-column>
            <el-table-column prop="name" label="促销名称" width=""></el-table-column>
            <el-table-column prop="isEnable" label="是否开启" width=""></el-table-column>
            <el-table-column prop="startTime" label="开始时间" width=""></el-table-column>
            <el-table-column prop="endTime" label="更新时间" width="160"></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--新增界面-->
        <el-dialog title="新增数据"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="80px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="促销名称" prop="name">
                    <el-input v-model="addForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="isMarketable">
                    <el-radio-group v-model="addForm.isMarketable">
                        <el-radio :label="true">开启</el-radio>
                        <el-radio :label="false">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="addForm.sort" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker v-model="addForm.startTime"
                                    type="datetime"
                                    placeholder="选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker v-model="addForm.endTime"
                                    type="datetime"
                                    placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="促销条件" prop="">
                    <el-button @click="showCondition">添加条件</el-button>
                    <template>
                        <el-table :data="conditions"
                                  style="width: 100%">
                            <el-table-column prop="code"
                                             label="条件代码"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="code"
                                             label="条件名称"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="parameter"
                                             label="参数">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form-item>
                <el-form-item label="促销结果" prop="">
                    <el-button @click="showResult">添加结果</el-button>
                    <template>
                        <el-table :data="results"
                                  style="width: 100%">
                            <el-table-column prop="code"
                                             label="结果代码"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="code"
                                             label="结果名称"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="parameter"
                                             label="参数">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="促销名称" prop="name">
                    <el-input v-model="editForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="isEnable">
                    <el-radio-group v-model="editForm.isEnable">
                        <el-radio :label="true">开启</el-radio>
                        <el-radio :label="false">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editForm.sort" auto-complete="off" style="width:100px"></el-input>
                    <span style="margin-left: 10px">数字越小、权重越大</span>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker v-model="editForm.startTime"
                                    type="datetime"
                                    placeholder="选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker v-model="editForm.endTime"
                                    type="datetime"
                                    placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="促销条件" prop="">
                    <el-button @click="showCondition">添加条件</el-button>
                    <template>
                        <el-table :data="conditions"
                                  style="width: 100%">
                            <el-table-column prop="code"
                                             label="条件代码"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="code"
                                             label="条件名称"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="parameter"
                                             label="参数">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form-item>
                <el-form-item label="促销结果" prop="">
                    <el-button @click="showResult">添加结果</el-button>
                    <template>
                        <el-table :data="results"
                                  style="width: 100%">
                            <el-table-column prop="code"
                                             label="结果代码"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="code"
                                             label="结果名称"
                                             width="180">
                            </el-table-column>
                            <el-table-column prop="parameter"
                                             label="参数">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑数据"
                   :visible.sync="conditionDialogVisible"
                   width="30%"
                   center>
            <el-form :model="conditionForm"
                     label-width="80px"
                     :rules="conditionFormRules"
                     ref="conditionForm">
                <el-form-item label="促销条件">
                    <el-select v-model="conditionForm.value" placeholder="请选择促销条件">
                        <el-option v-for="item in promotionConditionTypes"
                                   :key="item.sValue"
                                   :label="item.sDescription"
                                   :value="item.sKey">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="conditionDialogVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="conditionSubmit"
                           :loading="conditionLoading">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑数据"
                   :visible.sync="resultDialogVisible"
                   width="30%"
                   center>
            <el-form :model="resultForm"
                     label-width="80px"
                     :rules="resultFormRules"
                     ref="resultForm">
                <el-form-item label="促销条件">
                    <el-select v-model="resultForm.value" placeholder="请选择促销条件">
                        <el-option v-for="item in promotionResultTypes"
                                   :key="item.sValue"
                                   :label="item.sDescription"
                                   :value="item.sKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div slot="footer" class="dialog-footer">
                    <el-button @click.native="resultDialogVisible = false">取消</el-button>
                    <el-button type="primary"
                               @click.native="conditionSubmit"
                               :loading="conditionLoading">提交</el-button>
                </div>
            </el-form>

        </el-dialog>


        <!--指定用户发券界面-->
        <el-dialog title="指定用户发券界面"
                   :visible.sync="giftCouponFormVisible"
                   v-model="giftCouponFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="giftCouponForm"
                     label-width="80px"
                     :rules="giftCouponFormRules"
                     ref="giftCouponForm">
                <el-form-item label="用户ID" prop="name">
                    <el-input v-model="giftCouponForm.data" auto-complete="off"></el-input>
                    <span>每个用户ID之间用逗号','间隔</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="giftCouponFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="giftCouponSubmit"
                           :loading="giftCouponLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getPromotionListPage,
        addPromotion,
        getPromotion,
        getConditionCreate,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "", //双向绑定搜索内容
                    state: '',
                    type: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                roles: [],
                categorys: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                goodsTypeListAll: [],
                goodsTypeList: [],

                //新增界面配置信息
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                addForm: {},//新增界面数据

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据
                isResouceShow: 0,


                //促销条件界面配置信息
                conditionDialogVisible: false, //界面是否显示
                conditionLoading: false,
                conditionFormRules: {},
                conditionForm: {},//界面数据

                //促销结果界面配置信息
                resultDialogVisible: false, //界面是否显示
                cresultLoading: false,
                resultFormRules: {},
                resultForm: {},//界面数据

                value: [],

                //促销条件
                conditions: [],
                results: [],
                promotionConditionTypes: [],
                promotionResultTypes: [],
            };
        },
        methods: {

            inputChange(val) {
                this.filters.name = val;
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
            //获取列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    orderField: "sort",
                    orderDirection: "asc",
                };
                this.listLoading = true;
                getPromotionListPage(para).then((res) => {
                    this.total = res.data.count;
                    this.datalist = res.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************


            //显示新增界面
            handleAdd() {
                let that = this;
                that.addFormVisible = true;
                that.addLoading = false;
            },

            //显示编辑界面
            handleEdit() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的数据！",
                        type: "error",
                    });
                    return;
                }
                ///获取信息
                getPromotion({ id: row.id }).then((res) => {
                    console.log(res)
                    console.log(res.data.model);
                    console.log(res.data.promotionConditionTypes);
                    if (res.code == 0) {
                        that.editForm = res.data.model;
                        that.conditions = res.data.conditions;
                        that.results = res.data.results;
                        that.promotionConditionTypes = res.data.promotionConditionTypes;
                        that.promotionResultTypes = res.data.promotionResultTypes;
                    }
                });
                this.editLoading = false;
                that.editFormVisible = true;
            },
            showCondition() {
                console.log(this.conditions);
                this.conditionDialogVisible = true;
            },
            showResult() {
                console.log(this.conditions);
                this.resultDialogVisible = true;
            },
            //显示指定用户发券界面
            handleGiftCoupob() {
                let that = this;
                that.giftCouponFormVisible = true;
                that.giftCouponLoading = false;
            },
            //添加促销条件
            handleCondition() {
                let that = this;
                getPromotion({ id: row.id }).then((res) => {
                    if (res.code == 0) {
                        that.editForm = res.data;
                    }
                });
                this.editLoading = false;
                that.editFormVisible = true;
            },

            handleChange(value) {
                console.log(value);
            },

            //************提交方法************

            //新增
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            let para = Object.assign({}, this.addForm);
                            addPromotion(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.getList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.addFormVisible = false;
                            });
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let para = Object.assign({}, this.editForm);
                            editGoods(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },

            //新增促销条件
            conditionSubmit: function () {
                this.$message({
                    message: "新增促销条件",
                    type: "success",
                });
                //this.$refs.addForm.validate((valid) => {
                //    if (valid) {
                //        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                //            this.addLoading = true;
                //            let para = Object.assign({}, this.addForm);
                //            addPromotion(para).then((res) => {
                //                if (res.code == 0) {
                //                    this.addLoading = false;
                //                    this.$message({
                //                        message: res.msg,
                //                        type: "success",
                //                    });
                //                    this.$refs["addForm"].resetFields();
                //                    this.getList();
                //                } else {
                //                    this.$message({
                //                        message: res.msg,
                //                        type: "error",
                //                    });
                //                }
                //                this.addFormVisible = false;
                //            });
                //        });
                //    }
                //});
            },
            //新增促销结果
            resultSubmit: function () {
                this.$message({
                    message: "新增促销结果",
                    type: "success",
                });
                //this.$refs.addForm.validate((valid) => {
                //    if (valid) {
                //        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                //            this.addLoading = true;
                //            let para = Object.assign({}, this.addForm);
                //            addPromotion(para).then((res) => {
                //                if (res.code == 0) {
                //                    this.addLoading = false;
                //                    this.$message({
                //                        message: res.msg,
                //                        type: "success",
                //                    });
                //                    this.$refs["addForm"].resetFields();
                //                    this.getList();
                //                } else {
                //                    this.$message({
                //                        message: res.msg,
                //                        type: "error",
                //                    });
                //                }
                //                this.addFormVisible = false;
                //            });
                //        });
                //    }
                //});
            },


            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
