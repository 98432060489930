<template>
  <section>
    <!--搜索条件-->
    <el-form :inline="true" @submit.native.prevent>
      <el-form-item>
        <el-input
          v-model="filters.aftersalesId"
          placeholder="请输入售后单编号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="filters.orderId"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="filters.type"
          placeholder="请选择商品状态"
          clearable
        >
          <el-option label="请选择商品状态" value=""> </el-option>
          <el-option label="未收到货" value="1"> </el-option>
          <el-option label="已收到货" value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="filters.status"
          placeholder="请选择审核状态"
          clearable
        >
          <el-option label="请选择商品状态" value=""> </el-option>
          <el-option label="未审核" value="1"> </el-option>
          <el-option label="审核通过" value="2"> </el-option>
          <el-option label="审核拒绝" value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="block">
          <el-date-picker
            v-model="filters.createTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
      :data="users"
      highlight-current-row
      v-loading="listLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="aftersalesId"
        label="售后单号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="orderId"
        label="订单号"
        width="150"
      ></el-table-column>
      <el-table-column prop="type" label="商品状态" width="105">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.type == 0 ? 'success' : 'danger'"
            disable-transitions
          >
            {{ scope.row.type == 0 ? "已收到货" : "未收到货" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="statusName"
        label="状态"
        width="105"
      ></el-table-column>
      <el-table-column prop="userNickName" label="用户" width="105">
        <template slot-scope="scope">
          {{ scope.row.userNickName + "(" + scope.row.userId + ")" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="refundAmount"
        label="退款金额"
        width="105"
      ></el-table-column>
      <el-table-column prop="reason" label="退款原因"></el-table-column>
      <el-table-column
        prop="createTime"
        label="申请时间"
        width="130"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 1"
            @click="handleEdit(scope.row)"
            type="text"
            size="small"
            >操作</el-button
          >
          <el-button @click="handleDetails(scope.row)" type="text" size="small"
            >明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="limit"
        :total="total"
        style="float: right"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      title="编辑数据"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="售后单号">
          {{ editForm.aftersalesId }}
        </el-form-item>
        <el-form-item label="订单号">
          {{ editForm.orderId }}
        </el-form-item>
        <el-form-item label="用户">
          {{ editForm.userNickName }}
        </el-form-item>
        <el-form-item label="原因">
          {{ editForm.reason }}
        </el-form-item>
        <el-form-item label="图片">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, i) in editForm.images"
            :key="i"
            :preview-src-list="editForm.images"
          ></el-image>
        </el-form-item>
        <el-form-item label="退货商品">
          <el-table
            ref="multipleTable"
            :data="editForm.order.items"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="50"
              :selectable="selectable"
            ></el-table-column>
            <el-table-column label="商品名称">
              <template slot-scope="scope">{{
                scope.row.name + " " + scope.row.addon
              }}</template>
            </el-table-column>
            <el-table-column prop="sendNums" label="发货数量" width="80">
            </el-table-column>
            <el-table-column prop="reshipNums" label="售后数量" width="80">
              <template slot-scope="scope">{{
                scope.row.reshipNums + "(" + scope.row.reshipedNums + ")"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="atPresentReshipNums"
              label="退货数量"
              width="80"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.atPresentReshipNums"
                  auto-complete="off"
                ></el-input
              ></template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="退款金额" prop="refundAmount">
          <el-input
            v-model="editForm.refundAmount"
            auto-complete="off"
          ></el-input>
          <div>
            订单支付金额：{{ editForm.payedAmount }},已退款金额：{{
              editForm.refunded
            }}
          </div>
        </el-form-item>
        <el-form-item label="收货与否" prop="type">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="1">未收到货</el-radio>
            <el-radio :label="2">已收到货</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核结果" prop="status">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input v-model="editForm.mark" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="说明">
          1.
          选择”未收到货“是退未发货的商品，选择”已收到货“是退已发货的商品，选择”未收到货“不会生成退货单，选择”已收到货“会生成退货单，<span
            style="color: red"
            >未发货的商品和已发货的商品不能混合着退</span
          >。<br />
          2.
          "售后数量"里的数字包含已提交的售后和审核通过的售后的商品数量，后面如果有括号，表示已发货的商品的退货数量。
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="editSubmit"
          :loading="editLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!--查看详情-->
    <el-dialog
      title="查看详情"
      :visible.sync="detailsFormVisible"
      v-model="detailsFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="detailsForm"
        label-width="80px"
        :rules="detailsFormRules"
        ref="detailsForm"
      >
        <el-form-item label="售后单号">
          {{ detailsForm.aftersalesId }}
        </el-form-item>
        <el-form-item label="订单号">
          {{ detailsForm.orderId }}
        </el-form-item>
        <el-form-item label="用户">
          {{ detailsForm.userNickName }}
        </el-form-item>
        <el-form-item label="原因">
          {{ detailsForm.reason }}
        </el-form-item>
        <el-form-item label="图片">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, i) in detailsForm.images"
            :key="i"
            :preview-src-list="detailsForm.images"
          ></el-image>
        </el-form-item>
        <el-form-item label="退货商品">
          <el-table
            ref="multipleTable"
            :data="detailsForm.items"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column label="商品名称">
              <template slot-scope="scope">{{
                scope.row.name + " " + scope.row.addon
              }}</template>
            </el-table-column>
            <el-table-column
              prop="atPresentReshipNums"
              label="退货数量"
              width="80"
            >
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          {{ detailsForm.mark }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="detailsFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  billAftersalesGetPageList,
  getRoleListPage,
  billAftersalesGetEdit,
  billAftersalesGetDetails,
  billAftersalesDoEdit,
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";

export default {
  components: { Toolbar },
  data() {
    return {
      filters: {
        aftersalesId: "",
        orderId: "",
        type: "",
        status: "",
        createTime: "",
      },
      users: [],
      roles: [],
      total: 0,
      buttonList: [],
      currentRow: null,
      page: 1,
      limit: 20,
      listLoading: false,
      sels: [], //列表选中列
      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        // userName: [
        //   { required: true, message: "请输入登录名", trigger: "blur" },
        // ],
        // nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
      },
      //编辑界面数据
      editForm: {
        order: { items: [] },
      },

      //详情信息
      detailsFormVisible: false, //详情信息是否显示
      detailsLoading: false,
      detailsFormRules: {
        //userName: [{ required: true, message: "请输入登录名", trigger: "blur" },],
        //nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
      },
      //编辑界面数据
      detailsForm: { order: { items: [] } },
      multipleSelection: [],
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectable(row, index) {
      return row.atPresentReshipNums > 0; //为true的时候可以选，为false则不可选择
    },
    handSearch() {
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    //获取用户列表
    getList() {
      let para = {
        page: this.page,
        limit: this.limit,
        aftersalesId: this.filters.aftersalesId,
        orderId: this.filters.orderId,
        type: this.filters.type,
        status: this.filters.status,
        createTime: this.filters.createTime,
      };
      this.listLoading = true;
      //NProgress.start();
      billAftersalesGetPageList(para).then((res) => {
        this.total = res.data.count;
        this.users = res.data.data;
        this.listLoading = false;
        //NProgress.done();
      });
    },
    //显示编辑界面
    handleEdit(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的用户！",
          type: "error",
        });
        return;
      }
      this.editLoading = true;
      billAftersalesGetEdit({ id: row.aftersalesId }).then((res) => {
        if (res.code == 0) {
          this.editForm = res.data;
          this.editFormVisible = true;
          this.editLoading = false;
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //显示编辑界面
    handleDetails(row) {
      if (!row) {
        this.$message({
          message: "请选择要编辑的用户！",
          type: "error",
        });
        return;
      }
      this.detailsLoading = true;
      billAftersalesGetDetails({ id: row.aftersalesId }).then((res) => {
        if (res.code == 0) {
          this.detailsForm = res.data;
          this.detailsFormVisible = true;
          this.detailsLoading = false;
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //编辑
    editSubmit: function () {
      console.log(this.multipleSelection);
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            var para = { items: [] };
            para.aftersalesId = this.editForm.aftersalesId;
            para.status = this.editForm.status;
            para.type = this.editForm.type;
            para.refund = this.editForm.refundAmount;
            para.mark = this.editForm.mark;

            this.multipleSelection.forEach((element) => {
              this.editForm.items.push({
                id: parseInt(element.id),
                nums: parseInt(element.nums),
              });
            });

            // let para = Object.assign({}, this.editForm);
            billAftersalesDoEdit(para).then((res) => {
              if (res.code == 0) {
                this.editLoading = false;
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.getList();
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getList();
    getRoleListPage().then((res) => {
      this.roles = res.data.data;
    });
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  },
};
</script>

<style scoped>
</style>
