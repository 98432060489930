<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.nickName" placeholder="请输入昵称" @change="nickNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.mobile" placeholder="请输入手机号" @change="mobileChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.storeName" placeholder="请输入店铺" @change="storeNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="users"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="id" label="ID" width="" sortable></el-table-column>
            <el-table-column prop="avatarImage" label="头像" width="" sortable>
                <el-avatar :size="medium" :src="imageurl"></el-avatar>
            </el-table-column>
            <el-table-column prop="userName" label="用户名" width="" sortable></el-table-column>
            <el-table-column prop="nickName" label="昵称" width="" sortable></el-table-column>
            <el-table-column prop="mobile" label="手机号" width="" sortable></el-table-column>
            <!--性别[1男2女3未知]-->
            <el-table-column prop="sex" label="性别" width="" sortable>
                <template slot-scope="scope">
                    <el-tag>
                        {{ scope.row.sex == 0 ? '未设置' :scope.row.sex == 1 ? '男':scope.row.sex == 2 ? '女':'未知' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="balance" label="余额" width="" sortable></el-table-column>
            <el-table-column prop="point" label="积分" width="" sortable></el-table-column>
            <el-table-column prop="storeName" label="我的店铺" width="" sortable></el-table-column>
            <el-table-column prop="bindStoreName" label="绑定店铺" width="" sortable></el-table-column>
            <el-table-column prop="city" label="地区" width="" sortable></el-table-column>
            <el-table-column prop="status" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.status == 1 ? "正常" : "禁用" }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="createTime" label="注册时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="昵称" prop="nickName">
                    {{ editForm.nickName }}
                </el-form-item>
                <el-form-item label="密码" prop="passWord">
                    <el-tooltip placement="top">
                        <div slot="content">
                            如果不修改密码，可以不录入
                        </div>
                        <el-input v-model="editForm.passWord"
                                  show-password
                                  auto-complete="off"></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="editForm.status">
                        <el-radio :label="0">正常</el-radio>
                        <el-radio :label="1">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--充值界面-->
        <el-dialog title="充值"
                   :visible.sync="rechargeFormVisible"
                   v-model="rechargeFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="rechargeForm"
                     label-width="80px"
                     :rules="rechargeFormRules"
                     ref="rechargeForm">
                <el-form-item label="用户名" prop="nickName">
                    {{ rechargeForm.userName  }}
                </el-form-item>
                <el-form-item label="昵称" prop="userName">
                    {{ rechargeForm.nickName  }}
                </el-form-item>
                <el-form-item label="充值金额" prop="balance">
                    <el-input v-model="rechargeForm.balance" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="rechargeFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="rechargeSubmit"
                           :loading="rechargeLoading">提交</el-button>
            </div>
        </el-dialog>


        <!--编辑银行卡信息界面-->
        <el-dialog title="编辑银行卡信息"
                   :visible.sync="editBankFormVisible"
                   v-model="editBankFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editBankForm"
                     label-width="80px"
                     :rules="editBankFormRules"
                     ref="editBankForm">
                <el-form-item label="昵称" prop="nickName">
                    {{ editBankForm.nickName }}
                </el-form-item>
                <el-form-item label="银行名称" prop="bankName">
                    <el-input v-model="editBankForm.bankName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="账户地址" prop="bankAddress">
                    <el-input v-model="editBankForm.bankAddress" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户行" prop="accountBank">
                    <el-input v-model="editBankForm.accountBank" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="账户名" prop="accountName">
                    <el-input v-model="editBankForm.accountName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="卡号" prop="cardNumber">
                    <el-input v-model="editBankForm.cardNumber" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="editBankForm.idcard" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editBankFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editBankSubmit"
                           :loading="editBankLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getCoreCmsUserListPage,
        getRoleListPage,
        removeCoreCmsUser,
        rechargeCoreCmsUser,
        getUserBankInfo,
        editUserBankInfo,
        editCoreCmsUser,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '正常'
                }, {
                    value: '2',
                    label: '禁用'
                }],
                filters: {
                    nickName: "",
                    mobile: "",
                    storeName: "",
                    state: "",
                },
                users: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                imageurl: 'https://file02.16sucai.com/d/file/2014/0814/17c8e8c3c106b879aa9f4e9189601c3b.jpg',
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    userName: [{ required: true, message: "请输入登录名", trigger: "blur" },],
                    nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
                },
                //编辑界面数据
                editForm: {},

                ///充值弹窗
                rechargeFormVisible: false, //编辑界面是否显示
                rechargeLoading: false,
                rechargeFormRules: {
                    balance: [{ required: true, message: "请输入充值金额", trigger: "blur" },],
                },
                //充值界面数据
                rechargeForm: {},

                //编辑银行卡信息
                editBankFormVisible: false, //编辑银行卡界面是否显示
                editBankLoading: false,
                editBankFormRules: {
                    //userName: [{ required: true, message: "请输入登录名", trigger: "blur" },],
                    //nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
                },
                //编辑界面数据
                editBankForm: {},
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getCmsUsers();
            },
            nickNameChange(val) {
                this.filters.nickName = val;
            },
            mobileChange(val) {
                this.filters.mobile = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            //获取用户列表
            getCmsUsers() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    nickName: this.filters.nickName,
                    mobile: this.filters.mobile,
                    storeName: this.filters.storeName,
                    state: this.filters.state,
                };
                this.listLoading = true;
                //NProgress.start();
                getCoreCmsUserListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.users = res.data.data;
                    this.listLoading = false;
                    //NProgress.done();
                });
            },

            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的用户！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该用户吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        //NProgress.start();
                        let para = { id: row.id };
                        removeCoreCmsUser(para).then((res) => {
                            this.listLoading = false;
                            //NProgress.done();
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getCmsUsers();
                        });
                    })
                    .catch(() => { });
            },

            //显示编辑界面
            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的用户！",
                        type: "error",
                    });
                    return;
                }
                this.editFormVisible = true;
                this.editForm = Object.assign({}, row);
                console.log(this.editForm);
            },

            //显示编辑银行卡界面
            handleEditBank() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的用户！",
                        type: "error",
                    });
                    return;
                }
                //this.editBankForm = row;
                getUserBankInfo({ id: row.id }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        this.editBankForm = Object.assign({ nickName: row.nickName }, res.data);
                    }
                    else {
                         this.editBankForm = { nickName: row.nickName };
                    }
                    this.editBankFormVisible = true;
                    this.editBankLoading = false;
                });
            },
            //显示充值界面
            handleRecharge() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要充值的用户！",
                        type: "error",
                    });
                    return;
                }
                this.rechargeFormVisible = true;
                this.rechargeForm = Object.assign({}, row);
            },

            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.editForm);
                            /*para.passWord = "不知道";*/
                            editCoreCmsUser(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getCmsUsers();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //充值
            rechargeSubmit: function () {
                this.$refs.rechargeForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认给 【" + this.rechargeForm.nickName + "】 充值 【" + this.rechargeForm.balance + "】 吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            console.log(this.rechargeForm);
                            let para = Object.assign({}, this.rechargeForm);
                            rechargeCoreCmsUser(para).then((res) => {
                                if (res.code == 0) {
                                    this.rechargeLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["rechargeForm"].resetFields();
                                    this.rechargeForm = false;
                                    this.getCmsUsers();
                                    this.rechargeFormVisible = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //编辑银行卡信息
            editBankSubmit: function () {
                this.$refs.editBankForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editBankLoading = true;
                            let row = this.currentRow;
                            if (!row) {
                                this.$message({
                                    message: "请选择要编辑的用户！",
                                    type: "error",
                                });
                                return;
                            }
                            let para = Object.assign({ userId: row.id }, this.editBankForm);
                            editUserBankInfo(para).then((res) => {
                                if (res.code == 0) {
                                    this.editBankLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editBankForm"].resetFields();
                                    this.editBankFormVisible = false;
                                    this.getCmsUsers();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.editBankLoading = false;
                            });
                        });
                    }
                });
            },
            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getCmsUsers();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
