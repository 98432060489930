<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <!--列表-->
        <el-table :data="listData"
                  highlight-current-row
                  v-loading="listLoading"
                  @current-change="selectCurrentRow"
                  style="width: 100%">
            <el-table-column type="index" width="80"></el-table-column>
            <el-table-column prop="name"
                             label="角色名"
                             width
                             sortable></el-table-column>
            <el-table-column prop="description"
                             label="说明"
                             width
                             sortable></el-table-column>
            <el-table-column prop="createTime"
                             label="创建时间"
                             width
                             sortable></el-table-column>
            <!--<el-table-column prop="CreateBy" label="创建者" width="" sortable>-->
            <!--</el-table-column>-->
            <el-table-column prop="Enabled" label="状态" width="200" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.enabled ? 'success' : 'danger'"
                            disable-transitions>{{ scope.row.enabled ? "正常" : "禁用" }}</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column label="操作" width="150">
              <template scope>
                  <el-button v-for="item in buttonList" v-bind:key="item.id"
                    :type="
                      item.func &&
                      (item.func.toLowerCase().indexOf('handledel') != -1 ||
                        item.func.toLowerCase().indexOf('stop') != -1)
                        ? 'danger'
                        : 'primary'
                    "
                    :v-if="!item.isHide"
                    @click="callFunc(item)"
                    >{{ item.name }}</el-button
                  >
              </template>
            </el-table-column> -->
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="50"
                           :total="total"
                           style="float: right"></el-pagination>
        </el-col>

        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="角色名" prop="name">
                    <el-input v-model="editForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="enabled">
                    <el-select v-model="editForm.enabled" placeholder="请选择角色状态">
                        <el-option v-for="item in statusList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="说明" prop="description">
                    <el-input v-model="editForm.description"
                              auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--新增界面-->
        <el-dialog title="新增"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="80px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="角色名" prop="name">
                    <el-input v-model="addForm.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="enabled">
                    <el-select v-model="addForm.enabled" placeholder="请选择角色状态">
                        <el-option label="激活" value="true"></el-option>
                        <el-option label="禁用" value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="说明" prop="description">
                    <el-input v-model="addForm.description"
                              auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--设置授权-->
        <el-dialog title="设置授权"
                   :visible.sync="assignFormVisible"
                   v-model="assignFormVisible"
                   :close-on-click-modal="false">
            <el-tree :data="assignData"
                     show-checkbox
                     default-expand-all
                     node-key="value"
                     ref="tree"
                     :props="defaultProps"
                     highlight-current>
                <span class="custom-tree-node" slot-scope="{ node }">
                    <span>{{ node.label }}</span>
                    <el-tag size="small" type="success" v-if="node.data.isbtn">按钮</el-tag>
                    <el-tag size="small" type="info" v-if="node.data.ishide">隐藏</el-tag>
                </span>
            </el-tree>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="assignFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="assignSubmit"
                           :loading="assignLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import util from "../../../util/date";
    import {
        getRoleListPage,
        getPermissionTree,
        getRoleSet,
        setAssign,
        removeRole,
        editRole,
        addRole,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "",
                },
                buttonList: [],
                listData: [],
                statusList: [
                    { name: "激活", value: true },
                    { name: "禁用", value: false },
                ],
                total: 0,
                page: 1,
                listLoading: false,
                sels: [], //列表选中列
                currentRow: null,
                assignData: [], //批量导入按钮数据
                assignFormVisible: false, //批量导入按钮
                assignLoading: false,
                assignForm: [],
                defaultProps: {
                    children: "children",
                    label: "label",
                },
                addDialogFormVisible: false,
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
                    enabled: [{ required: true, message: "请选择状态", trigger: "blur" }],
                },
                //编辑界面数据
                editForm: {
                    id: 0,
                },
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
                    enabled: [{ required: true, message: "请选择状态", trigger: "blur" }],
                },
                //新增界面数据
                addForm: {},
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getRoles();
            },
            //获取列表
            getRoles() {
                let _this = this;
                let para = {
                    page: _this.page,
                    limit: 10,
                    key: _this.filters.name,
                };
                _this.listLoading = true;
                //NProgress.start();
                getRoleListPage(para).then((res) => {
                    _this.total = res.data.count;
                    _this.listData = res.data.data;
                    _this.listLoading = false;
                    //NProgress.done();
                });
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该记录吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        //NProgress.start();
                        let para = { id: row.id };
                        removeRole(para).then((res) => {
                            this.listLoading = false;
                            //NProgress.done();
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getRoles();
                        });
                    })
                    .catch(() => { });
            },
            //显示编辑界面
            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.editFormVisible = true;
                console.log(row);
                this.editForm = Object.assign({}, row);
            },
            //显示新增界面
            handleAdd() {
                this.addFormVisible = true;
                this.addForm = {};
            },
            //显示设置授权页面
            handleAssign() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要导入的一行数据！",
                        type: "error",
                    });
                    return;
                }

                let that = this;
                that.assignForm = [];
                that.assignData = [];
                that.assignLoading = true;
                that.assignFormVisible = true;
                let para = { isall: true };
                getPermissionTree(para).then((res) => {
                    ++that.isResouceShow;
                    if (res.data.code == 0) {
                        console.log(res);
                        this.assignData = res.data.data.children;
                    }
                    that.$refs.tree.setCheckedKeys([]);
                    that.assignLoading = false;
                });
                //获取权限
                getRoleSet({ rid: row.id }).then((res) => {
                    if (res.code == 0 && res.data.length > 0) {
                        console.log(res.data);
                        res.data.forEach(function (item) {
                            let node = that.$refs.tree.getNode(item);
                            if (node.isLeaf) {
                                that.$refs.tree.setChecked(node, true);
                            }
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.editForm);
                            editRole(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getRoles();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //新增
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.addForm);
                            addRole(para).then((res) => {
                                if (util.isEmt.format(res)) {
                                    this.addLoading = false;
                                    return;
                                }
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.addFormVisible = false;
                                    this.getRoles();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //设置授权
            assignSubmit: function () {
                let _this = this;
                if (_this.$refs.tree.getCheckedNodes().length == 0) {
                    _this.$message({
                        message: "请选择设置授权",
                        type: "error",
                    });
                } else {
                    _this.assignLoading = true;
                    _this.$refs.tree.getCheckedNodes(false, true).forEach(function (item) {
                        _this.assignForm.push(item.value);
                    });
                    if (_this.assignForm.length == 0) {
                        _this.$message({
                            message: "请选择授权信息",
                            type: "error",
                        });
                        return;
                    }
                    let row = _this.currentRow;
                    let para = { id: row.id, data: _this.assignForm };
                    setAssign(para).then((res) => {
                        _this.assignLoading = false;
                        if (res.code == 0) {
                            _this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            _this.assignFormVisible = false;
                        } else {
                            _this.$message({
                                message: res.msg,
                                type: "error",
                            });
                        }
                    });
                }
            },
            selsChange: function (sels) {
                this.sels = sels;
            },
            //批量删除
            batchRemove: function () {
                this.$message({
                    message: "该功能未开放",
                    type: "warning",
                });
            },
        },
        mounted() {
            this.getRoles();

            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            //第一种写法，每个页面都需要写方法，但是可以做特性化处理
            // this.getButtonList(routers);
            //第二种写法，封装到 permissionRouter.js 中
            this.buttonList = getButtonList(this.$route.path, routers);
            console.log(this.buttonList);
        },
    };
</script>

<style scoped>
</style>
