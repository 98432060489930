<template>
  <section>
    <!--工具条-->
    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

    <!--列表-->
    <el-table
      :data="listData"
      highlight-current-row
      default-expand-all
      v-loading="listLoading"
      @selection-change="selsChange"
      @current-change="selectCurrentRow"
      row-key="id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      style="width: 100%"
      ref="table"
    >
      <el-table-column type="index" width="80"></el-table-column>
      <el-table-column label="菜单/按钮" width="200">
        <template slot-scope="scope">
          <i class="fa" :class="scope.row.icon"></i>
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="PnameArr" label="父节点" width="" sortable>
      </el-table-column>-->
      <el-table-column prop="linkUrl" label="路由地址" width></el-table-column>
      <el-table-column prop="apiUrl" label="API接口" width></el-table-column>
      <el-table-column prop="orderSort" label="Sort" width></el-table-column>
      <el-table-column prop="isButton" label="是否按钮" width="100">
        <template slot-scope="scope">
          <el-tag
            :type="!scope.row.isButton ? 'success' : 'danger'"
            disable-transitions
            >{{ !scope.row.isButton ? "否" : "是" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="func" label="按钮事件" width></el-table-column>
      <el-table-column prop="isHide" label="是否隐藏" width="100">
        <template slot-scope="scope">
          <el-tag
            :type="!scope.row.isHide ? 'success' : 'danger'"
            disable-transitions
            >{{ !scope.row.isHide ? "否" : "是" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="isKeepAlive" label="keepAlive" width="100">
        <template slot-scope="scope">
          <el-tag
            :type="!scope.row.isKeepAlive ? 'success' : 'danger'"
            disable-transitions
            >{{ !scope.row.isKeepAlive ? "否" : "是" }}</el-tag
          >
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="150">
                <template scope="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button type="danger" size="small" @click="handleDel(scope.$index, scope.row)">删除</el-button>
                </template>
      </el-table-column>-->
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="50"
        :total="total"
        style="float: right"
      ></el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="editForm.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型">
          <el-radio-group @change="clkTypeEdit" v-model="editForm.menuType">
            <el-radio label="目录"></el-radio>
            <el-radio label="页面"></el-radio>
            <el-radio label="按钮"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="路由地址" prop="linkUrl">
          <el-tooltip placement="top">
            <div slot="content">
              如果是目录，请填‘-’字符
              <br />如果是按钮，请输入空格即可
              <br />如果是外链，请带上协议，比如 https://www.baidu.com
              <br />
            </div>
            <el-input v-model="editForm.linkUrl" auto-complete="off"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            v-model="editForm.description"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Icon" prop="icon">
          <el-input v-model="editForm.icon" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="enabled">
          <el-select v-model="editForm.enabled" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.Name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" prop="orderSort">
          <el-input
            type="number"
            v-model="editForm.orderSort"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="isButton" label="是否按钮" width sortable>
          <el-switch v-model="editForm.isButton"></el-switch>
        </el-form-item>
        <el-form-item label="按钮事件" prop="func">
          <el-tooltip
            class="item"
            effect="dark"
            content="只写方法名即可，不用带括号，比如：handleEdit"
            placement="top-start"
          >
            <el-input v-model="editForm.func" auto-complete="off"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="isHide" label="隐藏菜单" width sortable>
          <el-switch v-model="editForm.isHide"></el-switch>
        </el-form-item>
        <el-form-item prop="iskeepAlive" label="keepAlive" width sortable>
          <el-switch v-model="editForm.iskeepAlive"></el-switch>
        </el-form-item>
        <el-form-item prop="pidArr" label="父级菜单" width sortable>
          <el-cascader
            placeholder="请选择，支持搜索功能"
            style="width: 400px"
            v-model="editForm.pidArr"
            :options="options"
            filterable
            :key="isResouceShow"
            :props="{ checkStrictly: true, expandTrigger: 'hover' }"
            v-if="!editLoading"
          ></el-cascader>
          <el-cascader
            placeholder="加载中..."
            style="width: 400px"
            v-if="editLoading"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="apiUrl" label="API接口" width sortable>
          <el-select
            style="width: 100%"
            v-model="editForm.apiUrl"
            placeholder="请选择API"
            @change="changeApi"
          >
            <el-option :key="0" :value="0" :label="'无需api'"></el-option>
            <el-option
              v-for="item in modules"
              :key="item.Id"
              :value="item.apiUrl"
              :label="item.apiUrl"
            >
              <span style="float: left">{{ item.apiUrl }}</span>
              <el-tag type="success" size="small" style="margin-left: 10px">{{
                item.apiMethod
              }}</el-tag>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口方式" prop="apiMethod">
          <el-input v-model="editForm.apiMethod" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="editSubmit"
          :loading="editLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-width="80px"
        :rules="editFormRules"
        ref="addForm"
      >
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="addForm.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型">
          <el-radio-group @change="clkTypeEdit" v-model="addForm.menuType">
            <el-radio label="目录"></el-radio>
            <el-radio label="页面"></el-radio>
            <el-radio label="按钮"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="路由地址" prop="linkUrl">
          <el-tooltip placement="top">
            <div slot="content">
              如果是目录，请填‘-’字符
              <br />如果是按钮，请输入空格即可
              <br />如果是外链，请带上协议，比如 https://www.baidu.com
              <br />
            </div>
            <el-input v-model="addForm.linkUrl" auto-complete="off"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            v-model="addForm.description"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Icon" prop="icon">
          <el-input v-model="addForm.icon" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="enabled">
          <el-select v-model="addForm.enabled" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.Name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" prop="orderSort">
          <el-input
            type="number"
            v-model="addForm.orderSort"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="isButton" label="是否按钮" width sortable>
          <el-switch v-model="addForm.isButton"></el-switch>
        </el-form-item>
        <el-form-item label="按钮事件" prop="func">
          <el-tooltip
            class="item"
            effect="dark"
            content="只写方法名即可，不用带括号，比如：handleEdit"
            placement="top-start"
          >
            <el-input v-model="addForm.func" auto-complete="off"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="isHide" label="隐藏菜单" width sortable>
          <el-switch v-model="addForm.isHide"></el-switch>
        </el-form-item>
        <el-form-item prop="iskeepAlive" label="keepAlive" width sortable>
          <el-switch v-model="addForm.iskeepAlive"></el-switch>
        </el-form-item>
        <el-form-item prop="pidArr" label="父级菜单" width sortable>
          <el-cascader
            placeholder="请选择，支持搜索功能"
            style="width: 400px"
            v-model="addForm.pidArr"
            :options="options"
            filterable
            :key="isResouceShow"
            :props="{ checkStrictly: true, expandTrigger: 'hover' }"
            v-if="!editLoading"
          ></el-cascader>
          <el-cascader
            placeholder="加载中..."
            style="width: 400px"
            v-if="editLoading"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="apiUrl" label="API接口" width sortable>
          <el-select
            style="width: 100%"
            v-model="addForm.apiUrl"
            placeholder="请选择API"
            @change="changeApi"
          >
            <el-option :key="0" :value="0" :label="'无需api'"></el-option>
            <el-option
              v-for="item in modules"
              :key="item.Id"
              :value="item.apiUrl"
              :label="item.apiUrl"
            >
              <span style="float: left">{{ item.apiUrl }}</span>
              <el-tag type="success" size="small" style="margin-left: 10px">{{
                item.apiMethod
              }}</el-tag>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口方式" prop="apiMethod">
          <el-input v-model="addForm.apiMethod" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="addSubmit"
          :loading="addLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!--批量导入-->
    <el-dialog
      title="批量导入"
      :visible.sync="importFormVisible"
      v-model="importFormVisible"
      :close-on-click-modal="false"
    >
      <el-tree
        :data="importData"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="tree"
        :props="defaultProps"
        highlight-current
      >
        <span class="custom-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
          <span>
            <el-tag size="small" type="success" v-if="node.data.isButton"
              >按钮</el-tag
            >
            <el-tag size="small" type="info" v-if="node.data.isHide"
              >隐藏</el-tag
            >
          </span>
        </span>
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="importFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="importSubmit"
          :loading="importLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import util from "../../../util/date";
import {
  getAllControllerAndActionByAssembly,
  getPermissionTreeTable,
  removePermission,
  editPermission,
  addPermission,
  getPermissionTree,
  getModuleListPage,
  batchImport,
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";

export default {
  components: { Toolbar },
  data() {
    return {
      loadNodeMap: new Map(),
      buttonList: [],
      currentRow: null,
      options: [],
      filters: {
        Name: "",
      },
      listData: [],
      modules: [], //接口api列表
      statusList: [
        { Name: "激活", value: true },
        { Name: "禁用", value: false },
      ],
      total: 0,
      page: 1,
      listLoading: false,
      sels: [], //列表选中列
      importData: [], //批量导入按钮数据
      importFormVisible: false, //批量导入按钮
      importLoading: false,
      importForm: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
      },
      //编辑界面数据
      editForm: {
        id: 0,
      },
      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
      },
      //新增界面数据
      addForm: {},
      isResouceShow: 0,
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    changeApi(value) {
      console.log(value);
      if (value == 0) {
        this.editForm.apiMethod = "";
        this.addForm.apiMethod = "";
      } else {
        this.modules.forEach((item) => {
          if (item.apiUrl == value) {
            this.editForm.apiMethod = item.apiMethod;
            this.addForm.apiMethod = item.apiMethod;
          }
        });
      }
    },
    clkType() {
      this.addForm.isButton = false;
      if (this.addForm.menuType == "页面") {
        this.addForm.linkUrl = "";
      } else if (this.addForm.menuType == "目录") {
        this.addForm.linkUrl = "-";
      } else if (this.addForm.menuType == "按钮") {
        this.addForm.linkUrl = " ";
        this.addForm.isButton = true;
      }
    },
    clkTypeEdit() {
      this.editForm.isButton = false;
      if (this.editForm.menuType == "页面") {
        this.editForm.linkUrl = "";
      } else if (this.editForm.menuType == "目录") {
        this.editForm.linkUrl = "-";
      } else if (this.editForm.menuType == "按钮") {
        this.editForm.linkUrl = " ";
        this.editForm.isButton = true;
      }
    },
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getPermissions();
    },
    //获取列表
    getPermissions() {
      this.listLoading = true;
      //NProgress.start();
      getPermissionTreeTable().then((res) => {
        this.listData = res.data;
        this.listLoading = false;
        //NProgress.done();
      });
    },
    //删除
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });

        return;
      }
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          //NProgress.start();
          let para = { id: row.Id };
          removePermission(para).then((res) => {
            if (util.isEmt.format(res)) {
              this.listLoading = false;
              return;
            }
            this.listLoading = false;
            //NProgress.done();
            if (res.data.success) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }

            this.getPermissions();
          });
        })
        .catch(() => {});
    },
    //显示编辑界面
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });

        return;
      }
      let that = this;
      that.options = [];
      that.editForm = {};
      that.editLoading = true;
      that.editFormVisible = true;
      let para = { isall: false };
      getPermissionTree(para).then((res) => {
        ++that.isResouceShow;
          that.options.push(res.data.data);
          console.log(row);
        that.editForm = Object.assign({}, row);
        that.editLoading = false;
      });
    },
    //显示新增界面
    handleAdd() {
      this.options = [];
      this.addFormVisible = true;
      this.addLoading = true;
      this.addForm = {};
      let para = { isall: false };
      getPermissionTree(para).then((res) => {
        ++this.isResouceShow;
        this.options.push(res.data.data);
        this.addLoading = false;
      });
    },
    //批量导入
    handleImport() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导入的一行数据！",
          type: "error",
        });
        return;
      }
      //修改
      // row.name='111';
      // row.pid=0;
      // this.$refs.table.setCurrentRow(row);

      let that = this;
      that.importForm = [];
      that.importData = [];
      that.importLoading = true;
      that.importFormVisible = true;
      getAllControllerAndActionByAssembly().then((res) => {
        ++that.isResouceShow;
        if (res.code == 0) {
          let idIndex = 0;
          res.data.forEach(function (item) {
            let tn = {};
            tn.id = idIndex++;
            tn.label = item.name;
            tn.children = [];
            if (item.action && item.action.length > 0)
              item.action.forEach(function (citem) {
                tn.children.push({
                  id: idIndex++,
                  label: citem.name,
                  apiUrl: "/" + citem.controllerName + "/" + citem.actionName,
                  apiMethod: citem.method,
                  name: citem.description,
                  isButton: citem.isbutton,
                  isHide: citem.hide,
                  func: citem.func,
                });
              });
            that.importData.push(tn);
          });
        }
        that.importLoading = false;
      });
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            //NProgress.start();
            let para = Object.assign({}, this.editForm);
            para.pId = para.pidArr.pop();
            if (para.id == para.pId) {
              this.$message({
                message: "警告，父节点不能是自己！",
                type: "error",
              });
              this.editLoading = false;
              return;
            }
            editPermission(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.editLoading = false;
                return;
              }
              this.editLoading = false;
              if (res.data.code == 0) {
                this.editLoading = false;
                //NProgress.done();
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["editForm"].resetFields();
                this.$refs.table.setCurrentRow();
                this.editFormVisible = false;
                this.getPermissions();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            //NProgress.start();
            let para = Object.assign({}, this.addForm);
            para.pId = para.pidArr.pop();
            addPermission(para).then((res) => {
              this.addLoading = false;
              if (res.data.code == 0) {
                this.addLoading = false;
                //NProgress.done();
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["addForm"].resetFields();
                this.$refs.table.setCurrentRow();
                this.addFormVisible = false;
                this.getPermissions();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    importSubmit: function () {
      let _this = this;
      if (_this.$refs.tree.getCheckedNodes().length == 0) {
        _this.$message({
          message: "请选择导入按钮",
          type: "error",
        });
      } else {
        _this.importLoading = true;
        _this.$refs.tree.getCheckedNodes().forEach(function (item) {
          if (!item.children) {
            _this.importForm.push(item);
          }
        });
        let row = _this.currentRow;
        let para = { id: row.id, data: _this.importForm };
        batchImport(para).then((res) => {
          _this.importLoading = false;
          if (res.code == 0) {
            _this.$message({
              message: res.msg,
              type: "success",
            });
            _this.importFormVisible = false;
            _this.getPermissions();
          } else {
            _this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },
    refreshTableRow(rid) {
      if (this.loadNodeMap.has(rid)) {
        const { tree, treeNode, resolve } = this.loadNodeMap.get(rid);
        this.load(tree, treeNode, resolve);
      }
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    //批量删除
    batchRemove: function () {
      this.$message({
        message: "该功能未开放",
        type: "warning",
      });
    },
  },
  mounted() {
    this.getPermissions();
    getModuleListPage().then((res) => {
      this.modules = res.data;
    });
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  },
};
</script>

<style scoped>
</style>
