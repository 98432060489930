<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.orderNumber" placeholder="请输入配送单号" @change="orderNumberChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.storeName" placeholder="请输入超市名称" @change="storeNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectStateChange" @clear="selectStateClear" clearable>
                    <el-option v-for="item in select_state_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.deliveryClerk" placeholder="请输入配送人电话或姓名" @change="deliveryClerkChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="orderNumber" label="订单编号"></el-table-column>
            <el-table-column prop="deliverClerk" label="配送员"></el-table-column>
            <el-table-column prop="mobile" label="配送电话"></el-table-column>
            <el-table-column prop="storeName" label="超市名称"></el-table-column>
            <el-table-column label="收件人信息">
                <template slot-scope="scope">
                    <p style="margin-block-start: 0; margin-block-end: 0;">{{ scope.row.shipName }}</p>
                    <p style="margin-block-start: 0; margin-block-end: 0;">{{ scope.row.shipMobile }}</p>
                    <p style="margin-block-start: 0; margin-block-end: 0;">{{ scope.row.shipAddress }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="totalNums" label="商品总数"></el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <el-tag :type="(scope.row.state == 1||scope.row.state == 2||scope.row.state == 3) ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.state == 1 ? "等待取货" : scope.row.state == 2 ? "正在配送" : scope.row.state == 3 ? "配送完成" :"订单异常" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注信息"></el-table-column>
            <el-table-column prop="paymentTime" label="配送时间" width="160"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
        </el-table>

        <!--生成配送单界面-->
        <el-dialog title="修改配送单"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   @close="formclear"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="配送时间">
                    <div class="block">
                        <el-date-picker v-model="editForm.deliveryTime"
                                        type="daterange"
                                        align="right"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="editDatePickeChange"
                                        :default-time="['08:00:00', '23:59:59']">
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="配送员">
                    <el-cascader placeholder="请选择配送员，支持搜索"
                                 style="width: 220px"
                                 v-model="editForm.deliveryUId"
                                 :options="deliverClerkList"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 @change="deliverClerkChange"
                                 v-if="!getdeliverClerkLoading"
                                 clearable></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="getdeliverClerkLoading"></el-cascader>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="editForm.remarks" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>


        <!--显示详情-->
        <el-dialog title="详细信息"
                   :visible.sync="showDetailsFormVisible"
                   v-model="showDetailsFormVisible"
                   :close-on-click-modal="false">
            <el-table :data="showDetailsForm"
                      style="width: 100%">
                <el-table-column prop="imageUrl" label="商品图片">
                    <template slot-scope="scope">
                        <el-avatar shape="square" :size="50" :src="scope.row.imageUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="商品名称">
                </el-table-column>
                <el-table-column prop="nums" label="商品数量">
                </el-table-column>
                <el-table-column prop="realNums" label="实际数量">
                </el-table-column>
            </el-table>
        </el-dialog>


        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
    import {
        getRoleListPage,
        getDeliverOrderListPage,
        getDeliverClerkSelectList,
        getDeliverOrderEdit,
        editDeliverOrder,
        getDeliverOrderItemList,
        exportDeliverOrderList,
        getDeliverOrderToStore
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_state_options: [{
                    value: '1',
                    label: '未支付'
                }, {
                    value: '2',
                    label: '已支付'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                filters: {
                    orderNumber: "",
                    storeName: "", //双向绑定搜索内容
                    state: '',
                    deliveryClerk: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                selsids: [], //列表选中列订单号

                //生成配送单界面配置信息
                editFormVisible: false, //界面是否显示
                editLoading: false,
                editFormRules: {
                    //type: [{ required: true, message: "请选择店铺类型", trigger: "blur" }],
                },
                editForm: {},//生成配送单界面数据
                isResouceShow: 0,

                getdeliverClerkLoading: false,
                deliverClerkList: [],



                //详情界面配置信息
                showDetailsFormVisible: false, //编辑界面是否显示
                showDetailsLoading: false,
                showDetailsFormRules: {

                },
                showDetailsForm: {},//编辑界面数据
            };
        },
        methods: {
            formclear() {
                //this.address = "";
                //this.addressInfo = "";
                //this.logoImage = "";
                //this.businessLicenceImage = "";
                //this.fileList = [];
                //this.dialogImageUrl = '';
                //this.position = {};
            },
            handleClick(row) {
                //console.log(row);
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this[item.func].apply(this, item);
            },
            deliverClerkChange(val) {
                //console.log(val);
                if (val.length > 0) {
                    this.editForm.deliverClerkId = val[val.length - 1];
                }
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
            orderNumberChange(val) {
                this.filters.orderNumber = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            deliveryClerkChange(val) {
                this.filters.deliveryClerk = val;
            },
            selectStateChange(val) {
                this.filters.state = val;
            },
            selectStateClear() {
                this.filters.state = "";
            },
            datePickeChange(val) {
                if (val == null) {
                    this.filters.startTime = "";
                    this.filters.endTime = "";
                }
                else if (val.length == 2) {
                    this.filters.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate();
                    this.filters.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                }
            },
            editDatePickeChange(val) {
                if (val == null) {
                    this.editForm.startTime = "";
                    this.editForm.endTime = "";
                }
                else if (val.length == 2) {
                    this.editForm.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate() + " 00:00:00";
                    this.editForm.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                }
            },
            //获取列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    orderNumber: this.filters.orderNumber,
                    storeName: this.filters.storeName,
                    state: this.filters.state,
                    deliveryClerk: this.filters.deliveryClerk,
                    startTime: this.filters.startTime,
                    endTime: this.filters.endTime,
                };
                this.listLoading = true;
                getDeliverOrderListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************

            //显示编辑界面
            handleEdit() {
                let that = this;
                var idlist = that.selsids;
                if (idlist.length == 0) {
                    let row = this.currentRow;
                    if (!row) {
                        this.$message({
                            message: "请选择配送单！",
                            type: "error",
                        });
                        return;
                    }
                    idlist.push(row.orderNumber);
                }
                that.editForm = [];
                that.editFormVisible = true;
                that.getdeliverClerkLoading = true;

                ///如果设置单条信息，则需要获取旧数据
                ///如果设置多条数据，则不需要获取旧数据
                if (idlist.length == 1) {
                    console.log(idlist);
                    console.log(idlist[0]);
                    getDeliverOrderEdit({ id: idlist[0] }).then((res) => {
                        if (res.code == 0) {
                            that.editForm = res.data;
                            this.editLoading = false;
                        }
                    });
                }
                //获取配送员列表
                getDeliverClerkSelectList({}).then((res) => {
                    if (res.data.code == 0) {
                        that.getdeliverClerkLoading = false;
                        that.deliverClerkList = res.data.data;
                    }
                });
            },


            //查看商品详情
            handleDetails() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择数据！",
                        type: "error",
                    });
                    return;
                }
                console.log(row.orderNumber);
                getDeliverOrderItemList({ id: row.orderNumber }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        that.showDetailsForm = res.data;
                        this.showDetailsLoading = false;
                        that.showDetailsFormVisible = true;
                    }
                    else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
            },

            //************提交方法************

            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let that = this;
                            var idlist = that.selsids;
                            if (idlist.length == 0) {
                                let row = this.currentRow;
                                if (!row) {
                                    this.$message({
                                        message: "请选择配送单！",
                                        type: "error",
                                    });
                                    return;
                                }
                                idlist.push(row.orderNumber);
                            }
                            console.log(idlist);

                            let para = Object.assign({ ids: idlist }, this.editForm);
                            console.log(para);
                            editDeliverOrder(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                };
                                this.editLoading = false;
                            });
                        });
                    }
                });
            },

            //导出表格
            exportExcel() {
                let row = this.currentRow;
                //if (!row) {
                //    this.$message({
                //        message: "请选择要导出的店铺！",
                //        type: "error",
                //    });
                //    return;
                //}
                this.$confirm("确认导出吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    let para = {
                        page: this.page,
                        limit: this.limit,
                        storeName: this.filters.storeName,
                        type: this.filters.type,
                        payState: this.filters.payState,
                        startTime: this.filters.startTime,
                        endTime: this.filters.endTime,
                    };
                    //let para = { id: row.id };
                    exportOrdereList(para).then((res) => {
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            console.log(res.data);
                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },


            //导出表格
            exportExcelToStore() {
                ///如果已选择，则导出已选择数据，否则导出全部
                let row = this.currentRow;
                //if (!row) {
                //    this.$message({
                //        message: "请选择要导出的店铺！",
                //        type: "error",
                //    });
                //    return;
                //}
                this.$confirm("确认导出吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    let para = {
                        page: this.page,
                        limit: this.limit,
                        storeName: this.filters.storeName,
                        type: this.filters.type,
                        payState: this.filters.payState,
                        startTime: this.filters.startTime,
                        endTime: this.filters.endTime,
                    };
                    //let para = { id: row.id };
                    getDeliverOrderToStore(para).then((res) => {
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            console.log(res.data);
                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },

            selsChange: function (sels) {
                this.sels = sels;
                this.selsids = sels.map(item => item.orderNumber);
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
