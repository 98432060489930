<template>
    <section>
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.payid" placeholder="请输入支付编号" @change="payidChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.ordernumber" placeholder="请输入订单编号" @change="ordernumberChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.paynumber" placeholder="请输入第三方交易编号" @change="paynumberChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.type" placeholder="交易类型" @change="selectTypeChange" @clear="selectTypeClear" clearable>
                    <el-option v-for="item in select_type_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.payState" placeholder="支付状态" @change="selectPayStateChange" @clear="selectPayStateClear" clearable>
                    <el-option v-for="item in select_payState_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="block">
                    <el-date-picker v-model="filters.picker_end"
                                    type="daterange"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="datePickeChange"
                                    :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="paymentId" label="支付编号"></el-table-column>
            <el-table-column prop="sourceId" label="订单编号"></el-table-column>
            <el-table-column prop="tradeNo" label="第三方交易编号"></el-table-column>
            <el-table-column label="交易金额(￥)">
                <template slot-scope="scope">
                    <el-tag type="danger" disable-transitions>
                        ￥{{ scope.row.totalMoney }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="在线支付(￥)">
                <template slot-scope="scope">
                    <el-tag type="danger" disable-transitions>
                        ￥{{ scope.row.money }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="账户支付(￥)">
                <template slot-scope="scope">
                    <el-tag type="success" disable-transitions>
                        ￥{{ scope.row.accountMoney }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="payedMsg" label="支付信息"></el-table-column>
            <el-table-column prop="paymentCode" label="交易类型"></el-table-column>
            <el-table-column prop="status" label="交易状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 2 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.status == 2 ? "已支付" : "未支付" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
    import {
        getRoleListPage,
        getBillPaymentsListPage,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_payState_options: [{
                    value: '1',
                    label: '未支付'
                }, {
                    value: '2',
                    label: '已支付'
                }],
                select_type_options: [{
                    value: '1',
                    label: '微信支付'
                }, {
                    value: '5',
                    label: '通联微信支付'
                }, {
                    value: '4',
                    label: '余额支付'
                }, {
                    value: '6',
                    label: '组合支付'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                filters: {
                    payid: '',
                    ordernumer: '',
                    paynumber: '',
                    type: '',
                    payState: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                roles: [],
                locationList: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                selsOrderNumber: [], //列表选中列订单号
                addDialogFormVisible: false,

            };
        },
        methods: {
            handleClick(row) {
                console.log(row);
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
            payidChange(val) {
                this.filters.payid = val;
            },
            ordernumberChange(val) {
                this.filters.ordernumber = val;
            },
            paynumberChange(val) {
                this.filters.paynumber = val;
            },
            selectTypeChange(val) {
                this.filters.type = val;
            },
            selectTypeClear() {
                this.filters.type = "";
            },
            selectPayStateChange(val) {
                this.filters.payState = val;
            },
            selectPayStateClear() {
                this.filters.payState = "";
            },
            datePickeChange(val) {
                if (val == null) {
                    console.log("null");
                    this.filters.startTime = "";
                    this.filters.endTime = "";
                }
                else if (val.length == 2) {
                    console.log("success");
                    this.filters.startTime = val[0].getFullYear() + '-' + (val[0].getMonth() + 1) + '-' + val[0].getDate();
                    this.filters.endTime = val[1].getFullYear() + '-' + (val[1].getMonth() + 1) + '-' + val[1].getDate() + " 23:59:59";
                }
            },
            //获取列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    payid: this.filters.payid,
                    ordernumber: this.filters.ordernumber,
                    paynumber: this.filters.paynumber,
                    type: this.filters.type,
                    payState: this.filters.payState,
                    startTime: this.filters.startTime,
                    endTime: this.filters.endTime,
                };
                this.listLoading = true;
                getBillPaymentsListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************提交方法************

            selsChange: function (sels) {
                this.sels = sels;
                this.selsOrderNumber = sels.map(item => item.orderNumber);
                console.log(this.selsOrderNumber)
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
