<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <!--<el-table-column type="index" width=""></el-table-column>-->
            <el-table-column prop="sort" label="排序" width="" sortable></el-table-column>
            <el-table-column prop="title" label="标题" width="" sortable></el-table-column>
            <el-table-column prop="linkType" label="链接类型" width="" sortable></el-table-column>
            <el-table-column prop="linkUrl" label="链接参数" width="" sortable></el-table-column>
            <el-table-column prop="location" label="轮播位置" width="" sortable></el-table-column>
            <!--轮播位置[0首页轮播，1首页广告]-->
            <!--<el-table-column prop="location" label="轮播位置" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.location == 0 ? 'success' : scope.row.location == 1 ? 'danger' : 'danger'"
                            disable-transitions>
                        {{ scope.row.location == 0 ? "首页轮播" : scope.row.location == 1 ?"首页广告":"" }}
                    </el-tag>
                </template>
            </el-table-column>-->
            <el-table-column prop="area" label="区域限制" width="" sortable></el-table-column>
            <!--<el-table-column prop="pv" label="访问量" width="" sortable></el-table-column>-->
            <!--状态[0隐藏1显示]-->
            <el-table-column prop="state" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 1 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.state == 1 ? "显示" : "隐藏" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="50"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>


        <!--新增界面-->
        <el-dialog title="新增"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="80px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="addForm.sort" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="addForm.title" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="轮播位置" prop="location">
                    <!--<el-input v-model="addForm.location" auto-complete="off"></el-input>-->
                    <el-select v-model="addForm.location" placeholder="请选择" prop="location">
                        <el-option v-for="item in locationList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="链接地址" prop="linkType">
                    <div style="display:flex">
                        <el-select v-model="addForm.linkType" placeholder="请选择链接位置" prop="linkType">
                            <el-option v-for="item in lintypelist"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input v-model="addForm.linkUrl" placeholder="请输入链接参数" auto-complete="off"></el-input>
                    </div>
                </el-form-item>

                <!--<el-form-item label="链接地址" prop="linkUrl">
                    <el-input v-model="addForm.linkUrl" auto-complete="off"></el-input>
                </el-form-item>-->
                <el-form-item label="区域限制" prop="area">
                    <el-input v-model="addForm.area" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="addForm.state">
                        <el-radio :label="0">隐藏</el-radio>
                        <el-radio :label="1">显示</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="图片" prop="image">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleImageSuccess"
                               :before-upload="beforeImageUpload">
                        <img v-if="image" :src="image" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>


        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editForm.sort" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="editForm.title" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="轮播位置" prop="location">
                    <el-select v-model="editForm.location" placeholder="请选择" prop="location">
                        <el-option v-for="item in locationList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="链接地址" prop="linkType">
                    <div style="display:flex">
                        <el-select v-model="editForm.linkType" placeholder="请选择" prop="linkType">
                            <el-option v-for="item in lintypelist"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input v-model="editForm.linkUrl" placeholder="请输入参数" auto-complete="off"></el-input>
                    </div>
                </el-form-item>

                <!--<el-form-item label="链接地址" prop="linkUrl">
                    <el-input v-model="editForm.linkdata" placeholder="请输入参数" auto-complete="off"></el-input>
                </el-form-item>-->
                <!--<el-form-item label="链接地址" prop="linkUrl">
                    <el-input v-model="editForm.linkUrl" auto-complete="off"></el-input>
                </el-form-item>-->
                <el-form-item label="区域限制" prop="area">
                    <el-input v-model="editForm.area" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="editForm.state">
                        <el-radio :label="0">隐藏</el-radio>
                        <el-radio :label="1">显示</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="图片" prop="image">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleImageSuccess"
                               :before-upload="beforeImageUpload">
                        <img v-if="image" :src="image" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getBannerListPage,
        getBannerLocation,
        addBanner,
        getBanner,
        editBanner,
        updateBannerState,
        removeBanner,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                filters: {
                    name: "",
                },
                datalist: [],
                roles: [],
                locationList: [],
                lintypelist: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                //新增界面配置信息
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    //storeName: [{ required: true, message: "请输名称", trigger: "blur" },],
                },
                addForm: {},//新增界面数据

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    //storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据

                image: "",
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            //获取店铺列表
            getDataList() {
                this.image = "";
                let para = {
                    page: this.page,
                    key: this.filters.name,
                };
                this.listLoading = true;
                getBannerListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的轮播！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该轮播吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        removeBanner(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },
            //显示
            handleShow() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要显示的轮播！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认显示该轮播吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id, data: 1 };
                        updateBannerState(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },
            //隐藏
            handleHide() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要隐藏的轮播！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认隐藏该轮播吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id, data: 0 };
                        updateBannerState(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },
            //显示新增界面
            handleAdd() {
                let _this = this;
                getBannerLocation({}).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        _this.lintypelist = res.data.lintypelist;
                        _this.locationList = res.data.locationlist;
                        _this.addFormVisible = true;
                    }
                    console.log(this.locationList);
                    _this.addLoading = false;
                });
            },

            //显示编辑界面
            handleEdit() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的轮播！",
                        type: "error",
                    });
                    return;
                }
                getBanner({ id: row.id }).then((res) => {
                    console.log(res.code);
                    if (res.code == 0) {
                        that.locationList = [];
                        that.locationList = res.data.locationList;
                        that.lintypelist = res.data.lintypelist;
                        that.editForm = Object.assign({}, res.data);
                        that.image = res.data.image;
                        that.editFormVisible = true;
                    }
                    that.editLoading = false;
                });
            },
            handleChange(value) {
                console.log(value);
            },

            //************提交方法************
            //新增
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            let para = Object.assign({}, this.addForm);
                            //if (this.image == "" || this.image == undefined) {
                            //    this.$message({
                            //        message: "请上传图片",
                            //        type: "error",
                            //    });
                            //    this.addLoading = false;
                            //    return;
                            //}
                            para.image = this.image;
                            addBanner(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.addFormVisible = false;
                                    this.getDataList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                    this.addLoading = false;
                                }
                            });
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let para = Object.assign({}, this.editForm);
                            if (this.image == "" || this.image == undefined) {
                                this.$message({
                                    message: "请上传图片",
                                    type: "error",
                                });
                                this.editLoading = false;
                                return;
                            }
                            para.image = this.image;
                            editBanner(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getDataList();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                    this.editLoading = false;
                                }
                            });
                        });
                    }
                });
            },
            beforeImageUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },

            handleImageSuccess(res, file) {
                this.image = res.data.src;
            },
            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getDataList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
