<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.nickName" placeholder="请输入昵称" @change="nickNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.realName" placeholder="请输入真实姓名" @change="realNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.storeName" placeholder="请输入店铺名称" @change="storeNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectStateChange" @clear="selectStateClear" clearable>
                    <el-option v-for="item in select_state_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="avatar" label="头像" width="" sortable>
                <el-avatar :size="medium" :src="avatar"></el-avatar>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称" width=""></el-table-column>
            <el-table-column prop="realname" label="姓名" width=""></el-table-column>
            <!--<el-table-column prop="realName" label="当前任务单"></el-table-column>
            <el-table-column prop="realName" label="异常单"></el-table-column>-->
            <el-table-column prop="mobile" label="电话"></el-table-column>
            <el-table-column prop="emergencyContact" label="紧急联系人"></el-table-column>
            <el-table-column prop="emergencyMobile" label="紧急联系电话"></el-table-column>
            <!--<el-table-column prop="status" label="状态">
        <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'"
                    disable-transitions>
                {{ scope.row.status == 1 ? "正常" : scope.row.status == 2 ? "禁用" : "异常" }}
            </el-tag>
        </template>
    </el-table-column>-->
            bl
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>


        <!--显示详情-->
        <el-dialog title="详细信息"
                   :visible.sync="showDetailsFormVisible"
                   v-model="showDetailsFormVisible"
                   :close-on-click-modal="false">
            <template>
                <el-table :data="showDetailsForm"
                          style="width: 100%">
                    <el-table-column type="expand" style="display:flex;justify-content:flex-end;">
                        <template slot-scope="props">
                            <el-table :data="props.row.items" :show-header="false" style="width: 100% !important; margin-left: 50px;">
                                <el-table-column prop="name" align="center"
                                                 label="商品名称"
                                                 width="180">
                                    <template slot-scope="scope">
                                        <div>{{scope.row.name}}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="nums" align="center"
                                                 label="商品数量"
                                                 width="180">
                                    <template slot-scope="scope">
                                        <div>* {{scope.row.nums}}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderNumber" label="货单编号"></el-table-column>
                    <el-table-column prop="storeName" label="超市名称"></el-table-column>
                    <el-table-column prop="shipName" label="收件信息">
                        <template slot-scope="scope" width="120">
                            <div>{{scope.row.shipName}}</div>
                            <div>{{scope.row.shipMobile}}</div>
                            <div>{{scope.row.shipAddress}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nums" label="商品总数" width="80" align="center"></el-table-column>
                    <!--<el-table-column prop="deliveryStatus" label="状态" width="60" align="center"></el-table-column>-->

                    <el-table-column prop="deliveryStatus" label="状态">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.state == 3 ? 'success' : 'danger'"
                                    disable-transitions>
                                {{ scope.row.state == 1 ? "待取货" : scope.row.state == 2 ? "正在配送" : "配送完成" }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deliveryTime" label="配送时间"></el-table-column>
                </el-table>
            </template>
        </el-dialog>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>



    </section>
</template>

<script>
    import {
        getRoleListPage,
        getDeliverClerkListPage,
        getDeliverOrderList,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";

    export default {
        components: { Toolbar },
        data() {
            return {
                select_state_options: [{
                    value: '1',
                    label: '店长'
                }, {
                    value: '2',
                    label: '店员'
                }],
                filters: {
                    userName: "",
                    storeName: "", //双向绑定搜索内容
                    type: '',

                },
                datalist: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                //详情界面配置信息
                showDetailsFormVisible: false, //编辑界面是否显示
                showDetailsLoading: false,
                showDetailsFormRules: {

                },
                showDetailsForm: {},//编辑界面数据
                activeName: "1",
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            nickNameChange(val) {
                this.filters.nickName = val;
            },
            realNameChange(val) {
                this.filters.realName = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            selectStateChange(val) {
                this.filters.state = val;
            },
            selectStateClear() {
                this.filters.state = "";
            },
            //获取列表
            getList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    userName: this.filters.userName,
                    storeName: this.filters.storeName,
                    type: this.filters.type,
                };
                this.listLoading = true;
                getDeliverClerkListPage(para).then((res) => {
                    console.log(res);
                    this.datalist = res.data.data;
                    this.total = res.data.count;
                    this.listLoading = false;
                });
            },


            //查看商品详情
            handleDetails() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择数据！",
                        type: "error",
                    });
                    return;
                }
                getDeliverOrderList({ id: row.id }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {
                        that.showDetailsForm = res.data;
                        this.showDetailsLoading = false;
                        that.showDetailsFormVisible = true;
                    }
                    else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
            },

            //************按钮事件************

            selsChange: function (sels) {
                this.sels = sels;
            },
        },
        mounted() {
            this.getList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
    .demo-table-expand {
        font-size: 0;
    }

        .demo-table-expand label {
            width: 90px;
            color: #99a9bf;
        }

        .demo-table-expand .el-form-item {
            margin-right: 0;
            margin-bottom: 0;
            width: 50%;
        }
</style>
